import instance from './instance';

const salvalogLogin = (body: {
  login?: string;
  error?: string;
  organizacao_id?: number;
}) => {
  return instance.post('/salvarLogLogin/', body);
};
export default salvalogLogin;
