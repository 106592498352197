/* eslint-disable prettier/prettier */
import instance from './instance';
import { Idioma } from './types';

interface TraducaoConviteData {
  id: number;
  assunto_email: string
  conteudo_email: string
  envio_id: number
  pesquisa_id: number
  pesquisa_idioma_id: number
  texto_botao_email: string
}

interface TraducaoConvite {
  data: TraducaoConviteData;
  idiomaState: Idioma;
  success: boolean;
}

const inserirTraducaoConvite = async (body: {
  id: number,
  assunto: string,
  corpo: string,
  btn_texto: string,
  idiomaId: number,
  pesquisaId: number,
  envioId: number,
}): Promise<TraducaoConvite | undefined> => {
  try {
    const { data: response, status } = !body?.id
      ? await instance.post('/idioma/convite/', {
        assunto_email: body.assunto,
        conteudo_email: body.corpo,
        texto_botao_email: body.btn_texto,
        pesquisa_idioma_id: body.idiomaId,
        pesquisa_id: body.pesquisaId,
        envio_id: body.envioId,
      })
      : await instance.put('/idioma/convite/', {
        id: body.id,
        assunto_email: body.assunto,
        conteudo_email: body.corpo,
        texto_botao_email: body.btn_texto,
        pesquisa_idioma_id: body.idiomaId,
        pesquisa_id: body.pesquisaId,
        envio_id: body.envioId,
      });

    if (status === 201 && response.success && response.data) {
      return response;
    }
    throw new Error(
      `Erro Status: ${status} retorno: ${JSON.stringify(response)}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export default inserirTraducaoConvite;
