import instance from './instance';

interface IRestaurarRespondentes {
  success: boolean;
  id: { id: number };
}

const restaurarRespondente = async (
  respondente_id: number,
): Promise<IRestaurarRespondentes> => {
  const result = await instance.post(
    `/respondentes/restaurar/${respondente_id}`,
  );

  return result.data;
};
export default restaurarRespondente;
