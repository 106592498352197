import instance from './instance';

interface IFiltrosDTO {
  ordem_coluna_arquivo: number;
  organizacao_filtro_id: number;
}

interface IAlterarFiltros {
  lista_id: number;
  posicao_filtro: IFiltrosDTO[];
}

interface AlterarFiltroslData {
  success: boolean;
}

interface AlterarFiltrosPayload {
  data: AlterarFiltroslData;
}

const alterarFiltros = (
  body: IAlterarFiltros,
): Promise<AlterarFiltrosPayload> =>
  instance.put('/lista/filtro/alterar', body);

export default alterarFiltros;
