/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import { Input, InputProps } from 'reactstrap';

import styles from './styles.module.scss';

interface InputCheckBoxProps extends InputProps {
  label: string;
  className?: string;
}

const InputCheckBox: React.ForwardRefRenderFunction<any, InputCheckBoxProps> = (
  { label, className = '', ...rest },
  ref,
) => {
  return (
    <>
      <Input
        ref={ref}
        className={`${className} text-primary ${styles.container}`}
        color="primary"
        {...rest}
        type="checkbox"
      />

      {label}
    </>
  );
};

export default forwardRef(InputCheckBox);
