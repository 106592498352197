import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';
import Loader from 'components/Loader';
import { PrivateRoute } from './PrivateRoute';

const ContatosLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/ContatosLayout" */ 'components/layouts/ContatosLayout'
    ),
);

const Contatos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/Listagem" */ 'pages/Contatos/Listagem'
    ),
);

const CriarListaManual = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/CriarListaManual" */ 'pages/Contatos/CriarListaManual'
    ),
);

const PreviewLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/PreviewLista" */ 'pages/Contatos/PreviewLista'
    ),
);

const MapearLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/MapearLista" */ 'pages/Contatos/MapearLista'
    ),
);

const LeituraLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/MapearLista" */ 'pages/Contatos/LeituraLista'
    ),
);

const ProcessandoLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/ProcessandoLista" */ 'pages/Contatos/ProcessandoLista'
    ),
);

const MapearListaConfirmaçao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/MapearListaConfirmaçao" */ 'pages/Contatos/MapearListaConfirmaçao'
    ),
);

const CriarListaImportada = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/CriarListaImportada" */ 'pages/Contatos/CriarListaImportada'
    ),
);

const Quarentena = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Quarentena/pages/ListagemQuarentena'
    ),
);

const RespondenteQuarentena = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Quarentena/pages/RespondenteQuarentena'
    ),
);

const Blocklist = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Contatos/Blocklist/pages/ListagemBlocklist'
    ),
);

const TodosContatos = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Contatos/TodosContatos/pages/ListagemTodosContatos'
    ),
);

const DetalhesContato = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Contatos/DetalhesContato'
    ),
);

export const rotasContato = (HomeLayout: React.FC) => {
  return {
    path: '/contato',
    element: (
      <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
        <HomeLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'lista/importar-lista',
        index: true,
        element: <Contatos />,
      },
      {
        path: 'lista/:listaId/importar/manual',
        element: (
          <ContatosLayout proximaPagina="preview">
            <CriarListaManual />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/preview',
        element: (
          <ContatosLayout proximaPagina="mapear">
            <PreviewLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/mapear',
        element: (
          <ContatosLayout proximaPagina="leitura-arquivo">
            <MapearLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/leitura-arquivo',
        element: (
          <ContatosLayout proximaPagina="confirmacao">
            <LeituraLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/confirmacao',
        element: (
          <ContatosLayout>
            <MapearListaConfirmaçao />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/processando',
        element: (
          <ContatosLayout proximaPagina="lista-processando">
            <ProcessandoLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/importar/arquivo',
        element: (
          <ContatosLayout proximaPagina="preview">
            <CriarListaImportada />
          </ContatosLayout>
        ),
      },
      {
        path: 'quarentena',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <Quarentena />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'quarentena/:respondenteId/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <RespondenteQuarentena />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'blocklist',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <Blocklist />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'todos-contatos',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <TodosContatos />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'lista/:listaId/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <TodosContatos />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'detalhes/:respondenteId/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <React.Suspense fallback={<Loader />}>
              <DetalhesContato />
            </React.Suspense>
          </PrivateRoute>
        ),
      },
    ],
  };
};
