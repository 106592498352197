import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getRoleUsuarioAutenticado } from 'config/Roles';

import LocalStorageEnum from 'enums/LocalStorageEnum';

type PrivateRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactNode | any;
  permission?: string[];
};

const isAuth = () => {
  if (
    localStorage.getItem(LocalStorageEnum.TOKEN) === '' ||
    localStorage.getItem(LocalStorageEnum.TOKEN) === null
  ) {
    return false;
  }

  return true;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  permission,
}) => {
  const roleUsuarioAutenticado = getRoleUsuarioAutenticado();
  if (
    permission &&
    roleUsuarioAutenticado &&
    !permission.includes(roleUsuarioAutenticado)
  )
    return <Navigate to="/login" replace />;

  return isAuth() ? children : <Navigate to="/login" replace />;
};

export function PrivateRouteOutlet(): JSX.Element {
  return isAuth() ? <Outlet /> : <Navigate to="/login" replace />;
}
