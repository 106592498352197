import ApiPrivada from '../apiPrivada';

interface ModeloQuestionarioProps {
  tipo_questionario_id: number;
  pesquisa_id: number;
  idioma_id: number;
}

interface ModeloQuestionarioData {
  success: boolean;
}

interface ModeloQuestionarioPayload {
  data?: ModeloQuestionarioData;
  success?: boolean;
}

const getModeloQuestionario = async ({
  tipo_questionario_id,
  pesquisa_id,
  idioma_id,
}: ModeloQuestionarioProps): Promise<ModeloQuestionarioPayload> => {
  try {
    const modelo = await ApiPrivada('modelo-questionario').post('/', {
      tipo_questionario: tipo_questionario_id,
      pesquisa_id,
      idioma_id,
    });
    return modelo;
  } catch {
    return { success: false };
  }
};

export default getModeloQuestionario;
