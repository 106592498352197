import axios from 'axios';

import LocalStorageEnum from 'enums/LocalStorageEnum';

const baseURL = process.env.REACT_APP_BASE_URL_CX_SERVER;

const instance = axios.create({
  baseURL: `${baseURL}/pergunta`,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LocalStorageEnum.TOKEN);

    if (token && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
    }
    return config;
  },

  (error) => Promise.reject(error),
);

export default instance;
