import instance from './instance';
import { Idioma } from './types';

interface TraducaoAgradecimentoData {
  id: number;
  idioma_id: number;
  pagina_agradecimento: string;
  pesquisa_id: number;
}

interface TraducaoAgradecimento {
  data: TraducaoAgradecimentoData;
  idiomaState: Idioma;
  success: boolean;
}

const inserirTraducaoPaginaAgradecimento = async (body: {
  id?: number;
  agradecimento: string;
  idiomaId: number;
}): Promise<TraducaoAgradecimento | undefined> => {
  try {
    const { data: response, status } = await instance.put(
      '/idioma/agradecimento/',
      {
        id: body.id,
        pagina_agradecimento: body.agradecimento,
        idioma_id: body.idiomaId,
      },
    );

    if (status === 201 && response.success && response.data) {
      return response;
    }
    throw new Error(
      `Erro Status: ${status} retorno: ${JSON.stringify(response)}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export default inserirTraducaoPaginaAgradecimento;
