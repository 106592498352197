import instance from './instance';
import { Pesquisa } from '../../entities/pesquisa/pesquisa';

interface GetPesquisasByCategoriaId {
  categoria_id: number;
  filtros: {
    sorting_pesquisa_associada?: {
      type: string;
      id: string;
      desc: boolean;
    };
    status?: {
      type: string;
      id: string | number;
    };
    nome?: {
      type: string;
      nome?: string;
      status?: string;
    };
  };
}

const getPesquisasByCategoriaId = async ({
  categoria_id,
  filtros,
}: GetPesquisasByCategoriaId): Promise<Pesquisa[]> => {
  const result = await instance.post('/categoria/pesquisas', {
    categoria_id,
    filtros,
  });

  return result.data;
};

export default getPesquisasByCategoriaId;
