import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import {
  Modal as ModalDefault,
  ModalProps as ModalDefaultProps,
} from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

import styles from './styles.module.scss';

export interface ModalProps extends ModalDefaultProps {
  children: React.ReactNode;
  title?: string;
  maxWidth?: string | number;
  hideBtnClose?: boolean;
  cbOpen?(): void;
  cbClose?(): void;
}

export interface ModalRef {
  toggleModal?(): void;
  openModal?(): void;
  closeModal?(): void;
  isModalOpen?: boolean;
}

const Modal: React.ForwardRefRenderFunction<ModalRef, ModalProps> = (
  {
    children,
    hideBtnClose,
    title,
    maxWidth = 'none',
    cbOpen,
    cbClose,
    className,
    ...rest
  },
  ref,
) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleToggleModal = useCallback(() => {
    setModalIsOpen((state) => !state);
  }, []);

  const handleOpenModal = useCallback(() => {
    cbOpen && cbOpen();
    setModalIsOpen(true);
  }, [cbOpen]);

  const handleCloseModal = useCallback(() => {
    cbClose && cbClose();
    setModalIsOpen(false);
  }, [cbClose]);

  useImperativeHandle(ref, () => ({
    toggleModal: handleToggleModal,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    isModalOpen: modalIsOpen,
  }));

  return (
    <ModalDefault
      isOpen={modalIsOpen}
      toggle={handleCloseModal}
      style={{ maxWidth }}
      className={`${styles.modal} ${className}`}
      {...rest}
    >
      {(title || !hideBtnClose) && (
        <div className={styles.header}>
          <h5>{title}</h5>

          {!hideBtnClose && (
            <button
              type="button"
              className="btn-simple"
              onClick={handleCloseModal}
            >
              <FaTimes />
            </button>
          )}
        </div>
      )}

      {children}
    </ModalDefault>
  );
};

export default forwardRef(Modal);
