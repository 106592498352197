import instance from './instance';
import { Pagina } from './types';

interface PaginaData {
  msg: string;
  success: boolean;
  result: Pagina;
}

interface PaginaPayload {
  data: PaginaData;
}

const inserirPagina = async (body: {
  pesquisa_id: number;
  numero: number;
}): Promise<PaginaPayload> => instance.post('/pagina/inserir', body);

export default inserirPagina;
