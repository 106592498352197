import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';

import Button, { ButtonProps } from '..';

export interface ButtonControlDisabledProps extends ButtonProps {
  children: React.ReactNode;
}

export interface ButtonControlDisabledRef {
  disable(): void;
  enable(): void;
}

const ButtonControlDisabled: React.ForwardRefRenderFunction<
  ButtonControlDisabledRef,
  ButtonControlDisabledProps
> = ({ children, disabled, ...rest }, ref) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const disable = useCallback(() => {
    setIsDisabled(true);
  }, []);

  const enable = useCallback(() => {
    setIsDisabled(false);
  }, []);

  useImperativeHandle(ref, () => ({
    disable,
    enable,
  }));

  return (
    <Button disabled={disabled || isDisabled} {...rest}>
      {children}
    </Button>
  );
};

export default forwardRef(ButtonControlDisabled);
