import { useParams, useLocation } from 'react-router-dom';
import { usePesquisa } from '../pesquisa';
import {
  EstatisticaEmailEnvio,
  EnvioPesquisa,
  Listas,
  Lembrete,
} from '../types';

/**
 * `useEnvio` te retornará o objeto de pesquisa. Caso o parametro não seja passado, será retornado a pesquisa atual
 *
 */
const useEnvio = (id = 0): EnvioPesquisa | undefined => {
  const { envioId } = useParams();
  const pesquisa = usePesquisa();
  const { pathname } = useLocation();
  let idEnvio = id || envioId;

  if (!idEnvio) {
    const regexEnvio = new RegExp(/envio\/\d{1,}/, 'ig');
    if (regexEnvio.test(pathname)) {
      const [envioPath]: string[] = pathname.match(regexEnvio) || [];
      idEnvio = parseInt(envioPath.split('/')[1], 10);
    }
  }

  if (pesquisa && pesquisa.envios) {
    const envioAtual = pesquisa.envios.find(
      (itemEnvio) => itemEnvio.id === Number(idEnvio),
    );

    return envioAtual || pesquisa.envios[0];
  }
  return undefined;
};

/**
 * `useEnvios` te retornará o array de envios.
 */
const useEnvios = (): EnvioPesquisa[] | undefined => {
  const pesquisa = usePesquisa();
  if (pesquisa) return pesquisa.envios;
  return undefined;
};

const useContatoEnvios = (): Listas[] | undefined => {
  const envio = useEnvio();
  if (envio) return envio.listas;
  return undefined;
};

const useEstatiscasEnvio = ():
  | (EstatisticaEmailEnvio | undefined)[]
  | undefined => {
  const envio = useEnvio();

  if (envio) {
    if (envio?.estatistica_email?.length > 0) {
      const listaEstatistica = envio?.estatistica_email;

      const estatisticaEnvio = listaEstatistica?.find(
        (itemEstatistica) =>
          itemEstatistica?.lembrete_id === null &&
          itemEstatistica?.api_lembrete_numero === null,
      );
      const estatisticaLembrete = listaEstatistica
        .filter(
          (itemEstatistica) =>
            itemEstatistica?.lembrete_id !== null ||
            itemEstatistica?.api_lembrete_numero !== null,
        )
        .sort(
          (
            prev: EstatisticaEmailEnvio,
            next: EstatisticaEmailEnvio,
          ): number => {
            if (prev?.lembrete_id && next.lembrete_id)
              return prev.lembrete_id - next.lembrete_id;
            if (prev?.api_lembrete_numero && next.api_lembrete_numero)
              return prev.api_lembrete_numero - next.api_lembrete_numero;
            return 0;
          },
        );
      const estatisticaOrdenada = [estatisticaEnvio, ...estatisticaLembrete];

      return estatisticaOrdenada;
    }

    return envio?.estatistica_email;
  }
};

const useLembrete = (id: number): Lembrete | undefined => {
  const envio = useEnvio();

  if (envio?.lembretes) {
    const lembreteCorrente = envio?.lembretes?.find(
      (lembreteItem) => lembreteItem?.id === id,
    );
    return lembreteCorrente;
  }
  return undefined;
};

export {
  useEnvio,
  useEnvios,
  useContatoEnvios,
  useEstatiscasEnvio,
  useLembrete,
};
