import React from 'react';

import Loader from 'components/Loader';

const AlterarSenha = React.lazy(
  () => import(/* webpackChunkName: "Login" */ 'pages/Login'),
);

export const rotasAlterarSenha = {
  path: '/alterar-senha',
  element: (
    <React.Suspense fallback={<Loader />}>
      <AlterarSenha />
    </React.Suspense>
  ),
  children: [
    {
      path: ':token',
    },
  ],
};
