import instance from './instance';
import { Pergunta } from '../../entities/pergunta/pergunta';

interface AlterarPergunta extends Pergunta {
  pergunta_id: number;
}

interface AlterarData {
  success: boolean;
}

interface AlterarPayload {
  data: AlterarData;
}

const alterar = (body: AlterarPergunta): Promise<AlterarPayload> =>
  instance.put('/alterar', body);

export default alterar;
