import instance from './instance';
import { Envio } from './types';

interface IntervaloEnvioData {
  success: boolean;
  result: Envio;
}

interface IntervaloEnvioPayload {
  data: IntervaloEnvioData;
}

interface AlteraIntervaloEnvioApiProps {
  id: number;
  apiIntervalo: number;
  apiIntervaloIsHora: boolean;
  horarioComercial: boolean;
}

const alteraIntervaloEnvioApi = ({
  id,
  apiIntervalo,
  apiIntervaloIsHora,
  horarioComercial,
}: AlteraIntervaloEnvioApiProps): Promise<IntervaloEnvioPayload> =>
  instance.put('/envio/intervalo/', {
    id,
    apiIntervalo,
    apiIntervaloIsHora,
    horarioComercial,
  });

export default alteraIntervaloEnvioApi;
