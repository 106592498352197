import instance from './instance';

interface NovoIdioma {
  assunto_email: null;
  conteudo_email: null;
  id: number;
  idioma: string;
  idioma_id: 2;
  pagina_agradecimento: null;
  pergunta_idioma: null;
  pesquisa_id: number;
  pesquisa_idioma_status_id: number;
  status: string;
  texto_botao_email: null;
}

interface NovoIdiomaData {
  success: boolean;
  result: NovoIdioma;
}

interface NovoIdiomaPayload {
  data: NovoIdiomaData;
}

const adicionarNovoIdioma = (body: {
  pesquisa_id: number;
  idioma_id: number;
  pesquisa_idioma_status_id: number;
}): Promise<NovoIdiomaPayload> =>
  instance.post('/pesquisa/idioma/inserir/', body);

export default adicionarNovoIdioma;
