import instance from './instance';

interface IdiomaPadrao {
  data: {
    msg: string;
    success: boolean;
  };
}

const alterarIdiomaPadrao = (body: {
  id: number;
  idiomaId: number;
}): Promise<IdiomaPadrao> => instance.put('/idioma/padrao', body);

export default alterarIdiomaPadrao;
