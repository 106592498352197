import InputContainerWithCounter from 'components/InputContainerWithCounter';
import { useCriarCategorias } from '../../contexts/CriarCategoriaContext';
import styles from './styles.module.scss';

function FormCriarCategoria() {
  const { descricao, exemplo, nome, setFieldValue } = useCriarCategorias();

  return (
    <div className={` d-flex flex-1 ${styles.container}`}>
      <>
        <div className="d-flex flex-column">
          <span className="text-primary titulo-negrito m-0">
            Nome da categoria
          </span>
          <p className="my-2">
            Dê um título à categoria que represente o que será analisado.
          </p>
          <InputContainerWithCounter
            value={nome}
            maxLength={30}
            onChange={(val) => setFieldValue('nome', val)}
          />
        </div>

        <div className="d-flex flex-column pt-4">
          <span className="text-primary titulo-negrito m-0">
            Descrição da Categoria
          </span>
          <p className="my-2">
            Explique de maneira breve o tema tratado pela categoria, destacando
            as principais característica que as definem.
          </p>

          <InputContainerWithCounter
            value={descricao}
            maxLength={200}
            type="textarea"
            onChange={(val) => setFieldValue('descricao', val)}
          />
        </div>

        <div className="d-flex flex-column pt-4">
          <span className="text-primary titulo-negrito m-0">
            Exemplo de resposta
          </span>
          <p className="my-2">
            Dê um exemplo de resposta que se encaixe na categoria que você está
            criando. Isso vai ajudar a inteligência artificial a entender melhor
            o tipo de conteúdo que você quer incluir nessa categoria.
          </p>
          <InputContainerWithCounter
            value={exemplo}
            maxLength={200}
            type="textarea"
            onChange={(val) => setFieldValue('exemplo', val)}
          />
        </div>
      </>
    </div>
  );
}

export default FormCriarCategoria;
