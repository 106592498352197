import instance from './instance';

interface RecategorizarRespostaProps {
  resposta_id: number;
  categorias_id: string[];
}

const updateCategoriaResposta = async (
  body: RecategorizarRespostaProps,
): Promise<void> => {
  await instance.put('/categoria/resposta/atualizar', body);
};

export default updateCategoriaResposta;
