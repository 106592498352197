import TipoRolesUsuariosSistemaEnum from 'enums/TipoRolesUsuariosSistemaEnum';
import { getRoleUsuarioAutenticado } from './getRoleUsuarioAutenticado';
import { getRotasUsuarioAutenticado } from './getRotasUsuarioAutenticado';
import { getRoleUsuario } from './getRoleUsuario';

const permissaoTodasRoles = [
  TipoRolesUsuariosSistemaEnum.ADMIN_SISTEMA,
  TipoRolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO,
  TipoRolesUsuariosSistemaEnum.COLABORADOR,
  TipoRolesUsuariosSistemaEnum.VISUALIZADOR,
];

const permissaoSomenteAdminSistema = [
  TipoRolesUsuariosSistemaEnum.ADMIN_SISTEMA,
];

const permissaoTodosUsuarios = [
  TipoRolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO,
  TipoRolesUsuariosSistemaEnum.COLABORADOR,
  TipoRolesUsuariosSistemaEnum.VISUALIZADOR,
];

const permissaoTodosUsuariosExcetoVisualizador = [
  TipoRolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO,
  TipoRolesUsuariosSistemaEnum.COLABORADOR,
];

export {
  permissaoTodasRoles,
  permissaoSomenteAdminSistema,
  permissaoTodosUsuarios,
  permissaoTodosUsuariosExcetoVisualizador,
  getRoleUsuarioAutenticado,
  getRotasUsuarioAutenticado,
  getRoleUsuario,
};
