import instance from './instance';
import { Envio_configuracao_email } from '../../entities/pesquisa/envio_configuracao_email';

interface EmailData {
  email: Envio_configuracao_email;
  success: boolean;
}

interface EmailPayload {
  data: EmailData;
}

const inserirConfiguracaoEmail = async (
  body: Envio_configuracao_email,
): Promise<EmailPayload> => instance.post('/configuracao/email/inserir', body);

export default inserirConfiguracaoEmail;
