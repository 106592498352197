import instance from './instance';
import { Pesquisa_pagina_agradecimento } from '../../entities/pesquisa/pesquisa_pagina_agradecimento';

interface AgradecimentoData {
  msg: string;
  success: boolean;
  result: Pesquisa_pagina_agradecimento;
}

interface AgradecimentoPayload {
  data: AgradecimentoData;
}

const alterarPaginaAgradecimento = (
  body: Pesquisa_pagina_agradecimento,
): Promise<AgradecimentoPayload> =>
  instance.put('/questionario/agradecimento/alterar', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export default alterarPaginaAgradecimento;
