import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { IconBaseProps } from 'react-icons';

import styles from './styles.module.scss';

type SpinnerProps = IconBaseProps;

const Spinner: React.FC<SpinnerProps> = ({ className = '', ...rest }) => {
  return <FaSpinner className={`${styles.container} ${className}`} {...rest} />;
};

export default Spinner;
