import instance from './instance';
import { ICloseLoopEmailTratativa } from '../../entities/pesquisa/close_loop_email_tratativa';

export interface updateInfoEmailResponderTratativaProps {
  tratativas_id: number[];
  pesquisa_id: number;
  remetente: string | null;
  responder_a: string | null;
  cco?: string | null;
  assunto: string;
  logotipo: string | null | File;
  imagem_corpo_email?: string | null | File;
  cor_cabecalho: string;
  cor_rodape: string;
  conteudo: string;
}

const updateInfoEmailResponderTratativa = async (
  body: updateInfoEmailResponderTratativaProps,
): Promise<ICloseLoopEmailTratativa> => {
  const formData = new FormData();

  formData.append('tratativas_id', JSON.stringify(body.tratativas_id));

  Object.entries(body).forEach(([key, value]) => {
    if (key !== 'tratativas_id' && value !== null) {
      formData.append(key, value);
    }
  });

  return instance.put('/close-loop/responder-tratativa/atualizar', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export default updateInfoEmailResponderTratativa;
