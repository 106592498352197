/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import { Categoria } from '../../screens/SelecionarCategorias';

import styles from './styles.module.scss';

interface TagSelectorProps {
  tags: Categoria[];
  selectedItems: Record<string, any>;
  onRemove?: (remainingSelectedTags: Record<string, any>) => void;
}

export function TagSelector({
  tags,
  selectedItems,
  onRemove,
}: TagSelectorProps) {
  const items = useMemo(() => {
    return tags.reduce((acc, tag) => {
      acc[(tag as Categoria).id] = tag;

      return acc;
    }, {} as Record<string | number, Categoria>);
  }, [tags]);

  const tagsSelecionadas = Object.keys(selectedItems);
  const qtdTagsSelecionadas = tagsSelecionadas.length;

  function handleUnselectItem(itemId: string) {
    const newSelection = { ...selectedItems };

    if (itemId in newSelection) {
      delete newSelection[itemId];

      onRemove?.(newSelection);
    }
  }

  return (
    <div className={styles.tagSelectorMainContainer}>
      <div>
        <p>
          <b className="text-primary">{qtdTagsSelecionadas}</b>/15 categorias
          selecionadas:
        </p>
      </div>
      <div className={styles.tagsContainer}>
        {tagsSelecionadas.map((tagSelecionadaId) => {
          return (
            <span
              key={tagSelecionadaId}
              className={`${styles.tags}`}
              onClick={() => handleUnselectItem(tagSelecionadaId)}
            >
              {items[tagSelecionadaId].nome} <span>x</span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
