import instance from './instance';

interface RespostasPerguntaAberta {
  data_inicio: Date;
  resposta: string;
}

interface RespostasPerguntaAbertaData {
  result: RespostasPerguntaAberta[];
  success: boolean;
}

interface RespostasPerguntaAbertaPayload {
  data: RespostasPerguntaAbertaData;
}

const getUltimasRespostasPerguntaAberta = (body: {
  pergunta_id: number;
  total_registros: number;
  respostas_ids?: number[] | number;
}): Promise<RespostasPerguntaAbertaPayload> =>
  instance.post('/resultado/aberta/tabela/', body);

export default getUltimasRespostasPerguntaAberta;
