import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface DesativaUsuarioPayload {
  msg: 'Usuário desativado com sucesso.';
  success: true;
}

interface IDesativaUsuario {
  data: DesativaUsuarioPayload;
}

const desativaUsuario = (body: { id: number }): Promise<IDesativaUsuario> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.put('/desativar/', body, {
    headers: {
      Authorization: token.toString(),
    },
  });
};
export default desativaUsuario;
