import instance from './instance';

interface IRemoverRespondenteBlocklist {
  success: boolean;
  id: { id: number };
}

const removerRespondentesBlocklist = async (
  respondentes: number[],
): Promise<IRemoverRespondenteBlocklist> => {
  const result = await instance.post('/blocklist/remover', { respondentes });

  return result.data;
};
export default removerRespondentesBlocklist;
