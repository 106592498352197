import instance from './instance';

interface IdiomaPadrao {
  data: {
    msg: string;
    success: boolean;
  };
}

interface ExcluirIdiomaProps {
  data: { id: number };
}

const excluirIdioma = (body: ExcluirIdiomaProps): Promise<IdiomaPadrao> =>
  instance.delete('/idioma', body);

export default excluirIdioma;
