import instance from './instance';

interface RemoverData {
  success: boolean;
}

interface RemoverPayload {
  data: RemoverData;
}

const remover = (body: { id: number }): Promise<RemoverPayload> =>
  instance.delete('/remover', { data: body });

export default remover;
