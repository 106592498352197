/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';

interface IAuthenticatePayload {
  token: string;
  payload: string;
  vencimento_trial: any;
  auth: 0 | 1;
  msg?: string;
}

interface IAuthenticate {
  data: IAuthenticatePayload[];
}

const authenticate = (
  login: string,
  password: string,
): Promise<IAuthenticate> =>
  instance.post('/', {
    login,
    password,
  });
export default authenticate;
