import instance from './instance';

export type DetalhesRespondenteType = {
  celular?: string;
  data_criacao: Date;
  data_resolvente: Date | string;
  email: string;
  id: number;
  identificador: string | number;
  nome: string;
  nome_pesquisa: string;
  personalizado_1?: string;
  personalizado_2?: string;
  personalizado_3?: string;
  personalizado_4?: string;
  personalizado_5?: string;
  personalizado_6?: string;
  personalizado_7?: string;
  respondente_id: string;
};

export type PesquisasType = {
  quarentena_respondente_id: number;
  nome: string;
  data_criacao: Date;
  data_expiracao_quarentena: Date;
  tipo_quarentena: number;
};

interface IBuscaDetalhesRespondenteQuarentena {
  respondente: DetalhesRespondenteType[];
  pesquisas: PesquisasType[];
}

const buscaDetalhesRespondenteQuarentena = async (
  respondente_id?: string | undefined | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<IBuscaDetalhesRespondenteQuarentena> => {
  const result = await instance.get(
    `/quarentena/respondente/${respondente_id}`,
  );

  return result.data.detalhesRespondente;
};
export default buscaDetalhesRespondenteQuarentena;
