/* eslint-disable no-console */
import { put, call, takeLatest } from 'redux-saga/effects';

import toastr from 'toastr';
import PesquisaStatusEnum from 'enums/PesquisaStatusEnum';
import * as apiPesquisa from './services/pesquisa';
import * as apiPergunta from './services/pergunta';
import * as apiContato from './services/contato';
import * as apiExportar from './services/exportacao-arquivo';
import * as apiAuth from './services/auth';

import { getModeloQuestionario } from './services/modeloQuestionario';

import {
  LISTA_PESQUISAS,
  INSERIR_PESQUISA,
  DUPLICAR_PESQUISA,
  ORDENAR_PESQUISA,
  INSERIR_PAGINA,
  INSERIR_PERGUNTA,
  ALTERAR_PESQUISA,
  ALTERAR_PERGUNTA,
  INSERIR_CONFIGURACAO_EMAIL,
  BUSCA_PESQUISA_INDIVIDUAL,
  ALTERAR_CONFIGURACAO_EMAIL,
  INSERIR_LISTA,
  INSERIR_LISTA_ARQUIVO,
  PROCESSAR_LISTA,
  INSERIR_LISTA_MANUAL,
  ALTERAR_PAGINA_AGRADECIMENTO,
  ALTERAR_DATA_PAGINA_AGRADECIMENTO,
  BUSCA_LISTA,
  INSERIR_LISTA_ENVIO,
  ATUALIZAR_TOTAL_CONTATOS_ENVIO,
  CRIA_QUESTIONARIO,
  INSERIR_PERSONALIZAR_LAYOUT,
  ALTERAR_PERSONALIZAR_LAYOUT,
  IMPORTA_QUESTIONARIO_MODELO,
  ATUALIZA_NOME_ENVIO,
  AGENDA_ENVIO_RELATORIO,
  ALTERA_INTERVALO_ENVIO_API,
  FINALIZA_PESQUISA_API,
  ALTERA_PERFIL_GERENCIAMENTO,
  ALTERA_SENHA_USUARIO,
  INSERIR_TRADUCAO_PERGUNTA,
  INSERIR_TRADUCAO_CONVITE,
  INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO,
  PERGUNTA_REMOVIDA,
  REMOVER_LIMITE_RESPOSTA,
  NOVO_LIMITE_RESPOSTA,
  INSERT_LIMITE_RESPOSTA,
  INSERT_TEMPO_OCIOSIDADE,
  UPDATE_CONFIGURACAO_QUARENTENA,
  ALTERAR_NOME_LISTA,
  ARQUIVO_MODIFICADO
} from './actions/actionTypes';

import * as actions from './actions';

toastr.options = {
  positionClass: 'toast-bottom-right',
};

function* inserirPesquisa(action) {
  try {
    const pesquisaInsert = yield call(apiPesquisa.inserir, action.payload);

    if (pesquisaInsert?.data?.pesquisa) {
      yield put(actions.atualizaPesquisaIndividual(pesquisaInsert.data));

      toastr.success('Pesquisa inserida com sucesso');
    }
  } catch (e) {
    toastr.error('Erro ao criar a pesquisa, tente novamente.');

    yield put(actions.erroPesquisa('Erro ao inserir pesquisa'));
    yield console.log(e);
  }
}

function* duplicarPesquisa(action) {
  try {
    const pesquisaDuplicada = yield call(apiPesquisa.duplicarPesquisa, action.payload);

    if (pesquisaDuplicada?.data?.pesquisa_id) {
      const pesquisaData = yield call(apiPesquisa.getPesquisaIndividual, { id: pesquisaDuplicada?.data?.pesquisa_id })
      yield put(actions.atualizaPesquisaIndividual({ pesquisa: pesquisaData.data.result }));

      toastr.success('Pesquisa duplicada com sucesso');
    }
  } catch (e) {
    toastr.error('Erro ao duplicar a pesquisa, tente novamente.');

    yield put(actions.erroPesquisa('Erro ao duplicar pesquisa'));
    yield console.log(e);
  }
}

function* inserirLista(action) {
  try {
    const result = yield call(apiContato.inserirLista, action.payload);
    if (result?.data?.success) {
      yield put(
        actions.inserirListaResult({
          ...result.data.lista,
          acao: 'inserir-lista',
        }),
      );

      toastr.success('Lista inserida com sucesso');
    }
  } catch (e) {
    yield put(actions.erroContato('Erro ao inserir lista'));
    yield console.log(e);
  }
}

function* inserirListaArquivo(action) {
  try {
    const result = yield call(apiContato.inserirListaArquivo, action.payload);
    if (result?.data.success) {
      yield put(
        actions.inserirListaResult({
          ...result.data.lista,
          acao: 'inserir-arquivo',
        }),
      );

      toastr.success('Upload realizado com sucesso');
    }
  } catch (e) {

    if (
      e.response.data.msg === 'A lista selecionada excede o limite de contatos permitido.' || e.response.data.msg === 'O cabeçalho contém dados vazios, ou o arquivo está mal formatado.'
    ) {
      yield put(actions.erroContato(e.response.data.msg));
    } else {
      yield put(actions.erroContato('Erro ao inserir arquivo da lista'));
    }

    yield console.log(e);
  }
}

function* processarLista(action) {
  try {
    const result = yield call(apiContato.processarLista, action.payload);

    if (result.data.success) {
      const contatos = yield call(apiContato.buscaListas);

      if (contatos.data.success) {
        yield put(actions.buscaListaResult(contatos.data.result));
      }

      yield put(
        actions.processarListaResult({
          ...result.data,
          acao: 'processar-lista',
        }),
      );

      toastr.success('Processamento da lista foi iniciado');
    }
  } catch (e) {
    const errorMessage = e.response.data.msg || 'Erro ao processar lista';

    yield put(actions.erroContato(errorMessage));

    yield console.log(e);
  }
}

function* getPesquisas() {
  try {
    const result = yield call(apiPesquisa.getAll);

    if (result.data.success) {
      yield put(actions.atualizaPesquisas(result.data.result));
      yield put(actions.sucessoPesquisa());
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao buscar pesquisa'));
    yield console.log(e);
  }
}

function* getPesquisaIndvidual(action) {
  try {
    const result = yield call(
      apiPesquisa.getPesquisaIndividual,
      action.payload,
    );

    if (result.data.success) {
      yield put(actions.atualizaPesquisaIndividual(result.data.result));
      yield put(actions.sucessoPesquisa());
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao buscar pesquisa'));
    yield console.log(e);
  }
}

function* getModelo(action) {
  try {
    const result = yield call(getModeloQuestionario, action.payload);

    if (result.data.success) {
      yield call(getPesquisas);
      toastr.success('Modelo inserido com sucesso');
    } else {
      throw new Error('Questionario sem modelo');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao buscar Modelo'));
    toastr.error('Questionario sem modelo padrão');
  }
}
function* buscaLista() {
  try {
    const result = yield call(apiContato.buscaListas);

    if (result.data.success) {
      yield put(actions.buscaListaResult(result.data.result));
      yield put(actions.sucessoContato());
    }
  } catch (e) {
    yield put(actions.erroContato('Erro ao busca listas'));
    yield console.log(e);
  }
}

function* inserirPagina(action) {
  try {
    const result = yield call(apiPesquisa.inserirPagina, action.payload);
    if (result.data.success) {
      yield put(actions.atualizaPagina(result.data.result));
      toastr.success('Página inserida com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao inserir página'));
    yield console.log(e);
  }
}

function* ordenarPesquisa(action) {
  try {
    const result = yield call(apiPergunta.ordenar, action.payload);
    if (result.data.success) {
      yield put(actions.atualizaTodasPerguntas(result.data));
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao ordenar pesquisa'));
    yield console.log(e);
  }
}

function* inserirPergunta(action) {
  try {
    const result = yield call(apiPergunta.inserir, action.payload);
    if (result.data.success) {
      yield put(actions.atualizarPergunta(result.data.pergunta));
      toastr.success('Pergunta inserida com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao ordenar pesquisa'));
    yield console.log(e);
  }
}

function* alterarPesquisa(action) {
  try {
    const { payload } = action;
    let payloadStatus;
    const criacaoPesquisa = !!payload.criacao_pesquisa;
    if (payload.status_name || payload.criacao_pesquisa) {
      payloadStatus = payload.status_name;
    }
    delete payload.status_name;
    delete payload.criacao_pesquisa;

    const result = yield call(apiPesquisa.alterar, payload);
    if (result.data.success) {
      const newPayload = payloadStatus
        ? { ...payload, status: payloadStatus }
        : payload;
      result.data.pesquisa = { ...newPayload, ...result.data.pesquisa };

      yield put(actions.atualizaPesquisaIndividual(result.data));
      yield put(actions.sucessoPesquisa());
      if (payload.status_id === PesquisaStatusEnum.PAUSADA) {
        toastr.success('Pesquisa pausada com sucesso.');
      } else if (payload.status_id === PesquisaStatusEnum.CONCLUIDA) {
        toastr.success('Pesquisa concluída com sucesso.');
      } else if (payload.status_id === PesquisaStatusEnum.EXCLUIDA) {
        toastr.success('Pesquisa excluída com sucesso.');
      } else if (
        !payload.data_disparo &&
        payload.status_id &&
        !criacaoPesquisa
      ) {
        toastr.success('Pesquisa retomada com sucesso.');
      } else if (criacaoPesquisa) {
        toastr.success('Pesquisa criada com sucesso.');
      } else {
        toastr.success('Pesquisa alterada com sucesso');
      }
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar pergunta'));
    yield console.log(e);
  }
}
function* criaQuestionario(action) {
  try {
    const result = yield call(apiPesquisa.alterar, action.payload);
    if (result.data.success) {
      yield put(actions.atualizaPesquisaIndividual(result.data));
      yield put(actions.sucessoPesquisa());
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar pergunta'));
    yield console.log(e);
  }
}

function* alterarPergunta(action) {
  try {
    const result = yield call(apiPergunta.alterar, action.payload);
    if (result.data.success) {
      yield put(actions.atualizaTodasPerguntas(result.data));
      toastr.success('Pergunta alterada com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar pergunta'));
    yield console.log(e);
  }
}

function* inserirConfiguracaoEmail(action) {
  try {
    const result = yield call(
      apiPesquisa.inserirConfiguracaoEmail,
      action.payload,
    );
    if (result.data.success) {

      yield put(actions.atualizaConfiguracaoEmail(result.data.email));

      toastr.success('Configuração inserida com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao configurar e-mail'));
    yield console.log(e);
  }
}

function* alterarConfiguracaoEmail(action) {
  try {
    const result = yield call(
      apiPesquisa.alterarConfiguracaoEmail,
      action.payload,
    );
    if (result.data.success) {
      yield put(
        actions.resultadoConfiguracaoEmail({
          emailResult: result.data.success,
        }),
      );
      yield put(actions.atualizaConfiguracaoEmail(result.data.email));

      toastr.success('Configuração alterada com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar configuração de e-mail'));
    yield console.log(e);
  }
}

function* adicionarListaManual(action) {
  try {
    const result = yield call(apiContato.adicionarListaManual, action.payload);
    if (result.data && result.data.success) {
      const contatos = yield call(apiContato.buscaListas);
      if (contatos.data.success) {
        yield put(actions.buscaListaResult(contatos.data.result));
      }

      yield put(
        actions.inserirListaResult({
          ...result.data.lista,
          acao: 'inserir-arquivo',
        }),
      );

      toastr.success('Upload realizado com sucesso');
    }
  } catch (e) {
    if (
      e.response.data.msg === 'A lista selecionada possui mais de 19999 contatos.'
    ) {
      yield put(actions.erroContato(e.response.data.msg));
    } else {
      yield put(actions.erroContato('Erro ao inserir arquivo da lista'));
    }
    yield console.log(e);
  }
}

function* alterarPaginaAgradecimento(action) {
  try {
    const result = yield call(
      apiPesquisa.alterarPaginaAgradecimento,
      action.payload,
    );

    if (result?.data.success) {
      yield put(
        actions.alterarAgradecimentoResult({
          ...result.data,
          acao: 'alterar-agradecimento',
        }),
      );
      yield put(actions.sucessoPesquisa());
      toastr.success('Página de agradecimento alterada com sucesso');
    }
  } catch (e) {
    yield put(
      actions.erroPesquisa('Erro ao editar a página de agradecimento.'),
    );
    yield console.log(e);
  }
}

function* alterarDataPaginaAgradecimento(action) {
  try {
    const result = yield call(
      apiPesquisa.alterarPaginaAgradecimento,
      action.payload,
    );

    if (result?.data.success) {
      yield put(
        actions.alterarDataAgradecimentoResult({
          ...result.data,
        }),
      );
      yield put(actions.sucessoPesquisa());
    }
  } catch (e) {
    yield put(
      actions.erroPesquisa('Erro ao editar a página de agradecimento.'),
    );
    yield console.log(e);
  }
}

function* inserirListaEnvio(action) {
  try {
    const result = yield call(apiPesquisa.inserirListaEnvio, action.payload);
    if (result.data.success) {
      yield put(
        actions.atualizaListaEnvio({
          lista: result.data.result,
          pesquisaId: action.payload.pesquisa_id,
          envioId: action.payload.envio_id,
        }),
      );
      toastr.success('Lista(s) inserida(s) com sucesso');
    }
  } catch (e) {
    yield put(actions.erroContato('Erro ao inserir lista'));
    yield console.log(e);
  }
}

function* atualizarTotalContatosEnvio(action) {
  try {
    const { pesquisa_id, ...payload } = action.payload;
    const result = yield call(apiPesquisa.atualizarTotalContatosEnvio, payload);
    if (result.data.success) {
      yield put(
        actions.atualizarEnvioPesquisa({
          envio: {
            id: action.payload.envio_id,
            total_contatos: action.payload.total_contatos,
            pesquisa_id,
          },
        }),
      );
      toastr.success('Total de contatos atualizado com sucesso.');
    }
  } catch (e) {
    yield put(actions.erroContato('Erro ao atualizar o total de contatos'));
    yield console.log(e);
  }
}

function* inserirPersonalizarLayout(action) {
  try {
    const result = yield call(
      apiPesquisa.inserirPersonalizarLayout,
      action.payload,
    );
    if (result.data.success) {
      yield put(actions.atualizaLayoutPersonalizado(result.data.result));
      toastr.success('Personalização de layout concluída com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao personalizar o layout.'));
    toastr.error('Erro ao personalizar layout');
    yield console.log(e);
  }
}

function* alterarPersonalizarLayout(action) {
  try {
    const result = yield call(
      apiPesquisa.alterarPersonalizarLayout,
      action.payload,
    );
    if (result.data.success) {
      yield put(actions.atualizaLayoutPersonalizado(result.data.result));
      toastr.success('Personalização de layout concluída com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao personalizar o layout.'));
    yield console.log(e);
  }
}
function* alteraNomeEnvio(action) {
  try {
    const result = yield call(apiPesquisa.alteraNomeEnvio, action.payload);
    if (result.data.success) {
      yield put(
        actions.atualizarEnvioPesquisa({
          envio: {
            id: action.payload.id,
            pesquisa_id: action.payload.pesquisa_id,
            nome: action.payload.nome.trim(),
          },
        }),
      );
      toastr.success('Nome do envio alterado com sucesso.');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar nome do envio.'));
    yield console.log(e);
  }
}
function* agendaEnvioRelatorio(action) {
  try {
    yield put(actions.ativaLoader());
    yield call(apiExportar.agendaEnvioRelatorio, action.payload);

    yield put(actions.desativaLoader());
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar nome do envio.'));
    yield console.log(e);
  }
}

function* alteraIntervaloEnvioApi(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(
      apiPesquisa.alteraIntervaloEnvioApi,
      action.payload,
    );

    if (result.data.success) {
      yield put(actions.resultEnvio(result?.data?.result));

      // yield put(
      //   actions.atualizarEnvioPesquisa({
      //     envio: {
      //       ...result?.data?.result,
      //     },
      //   }),
      // );
    } else {
      yield put(actions.desativaLoader());
    }

    // yield put(actions.desativaLoader());
    toastr.success('Intervalo de envio alterado com sucesso.');
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar intervalo de envio API.'));
    yield console.log(e);
  }
}

function* finalizaPesquisaApi(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(apiPesquisa.finalizaPesquisaApi, action.payload);

    if (result.data.success) {
      yield put(actions.atualizaPesquisaIndividual(result.data.result));
      yield put(actions.resultEnvio(result?.data));
    }

    // yield put(actions.desativaLoader());
    toastr.success('Pesquisa criada com sucesso.');
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar intervalo de envio API.'));
    yield console.log(e);
  }
}

function* alteraNomeEmailUsuario(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(apiAuth.alteraNomeEmail, action.payload);
    if (result.data.success) {
      apiAuth.atualizaLocalStoreAuth(result.data.token);
    }

    yield put(actions.desativaLoader());
    toastr.success('Perfil alterado com sucesso.');
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao alterar perfil'));
    if (e.toString().includes('302')) {
      toastr.warning('Esse perfil não pode ser alterado');
    }
    yield console.log(e);
  }
}

function* alteraSenhaUsuario(action) {
  try {
    yield put(actions.ativaLoader());
    yield call(apiAuth.alterarSenhaApp, action.payload);
    yield put(actions.desativaLoader());
    yield (document.getElementById('senhaAtual').value = '');
    yield (document.getElementById('senhaNova').value = '');
    toastr.success('Senha alterada com sucesso');
  } catch (e) {
    toastr.error(e?.response?.data?.msg || e.message);
    yield console.log(e);
    yield put(actions.desativaLoader());
  }
}

function* inserirTraducaoPergunta(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(
      apiPesquisa.inserirTraducaoPergunta,
      action.payload,
    );

    if (result.success) {
      yield put(
        actions.updateIdioma({
          idiomaState: result.idiomaState,
        }),
      );
    }
    yield put(actions.desativaLoader());
    action.payload.handlerModal();

    toastr.success('Tradução inserida com sucesso.');
  } catch (e) {
    toastr.error(e?.response?.data?.msg || e.message);
    yield console.log(e);
    yield put(actions.desativaLoader());
    action.payload.handlerModal();
  }
}

function* inserirTraducaoPaginaAgradecimento(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(
      apiPesquisa.inserirTraducaoPaginaAgradecimento,
      action.payload,
    );

    if (result.success) {
      yield put(
        actions.updateIdioma({
          idiomaState: result.idiomaState,
        }),
      );
    }

    yield put(actions.desativaLoader());
    action.payload.handlerModal();

    toastr.success('Tradução inserida com sucesso.');
  } catch (e) {
    toastr.error(e?.response?.data?.msg || e.message);
    yield console.log(e);
    yield put(actions.desativaLoader());
    action.payload.handlerModal();
  }
}

function* inserirTraducaoConvite(action) {
  try {
    yield put(actions.ativaLoader());
    const result = yield call(
      apiPesquisa.inserirTraducaoConvite,
      action.payload,
    );

    if (result.success) {
      yield put(
        actions.updateIdioma({
          idiomaState: result.idiomaState,
        }),
      );
    }

    yield put(actions.desativaLoader());
    action.payload.handlerModal();

    toastr.success('Tradução inserida com sucesso.');
  } catch (e) {
    toastr.error(e?.response?.data?.msg || e.message);
    yield console.log(e);
    yield put(actions.desativaLoader());
    action.payload.handlerModal();
  }
}

function* perguntaQuestionarioRemovida(action) {
  try {
    const result = yield call(apiPergunta.remover, action.payload);

    if (result.status === 200) {
      yield put(actions.atualizaTodasPerguntas(result.data));

      if (result.data.idiomaState) {
        yield put(
          actions.updateIdioma({
            idiomaState: result.data.idiomaState,
          }),
        );
      }
    }
  } catch (e) {
    toastr.error('Erro ao excluir a pergunta, tente novamente.');
    yield console.log(e);
  }
}

function* removeLimiteRespostaPesquisa(action) {
  try {
    const result = yield call(
      apiPesquisa.updateLimiteRespostaByPesquisaId,
      action.payload,
    );

    if (result.status === 200 && result.data.success) {
      yield put(
        actions.updateLimiteResposta({
          pesquisa_id: action.payload.pesquisa_id,
          limite_resposta: null,
        }),
      );
    }
  } catch (e) {
    yield console.log(e);
  }
}

function* atualizaNovoLimiteRespostaPesquisa(action) {
  try {
    const result = yield call(
      apiPesquisa.updateLimiteRespostaByPesquisaId,
      action.payload,
    );

    if (result.status === 200) {
      yield put(
        actions.updateLimiteResposta({
          pesquisa_id: action.payload.pesquisa_id,
          limite: action.payload.limite,
        }),
      );
    }
  } catch (e) {
    yield console.log(e);
  }
}

function* insertLimiteResposta(action) {
  try {
    const result = yield call(
      apiPesquisa.insertLimiteRespostaByPesquisaId,
      action.payload,
    );

    if (result.status === 200) {
      yield put(
        actions.updateArrayLimiteResposta({
          id: result.data.id,
          pesquisa_id: result.data.pesquisa_id,
          ativo: result.data.ativo,
          limite: result.data.limite,
        }),
      );
    }
  } catch (e) {
    yield console.log(e);
  }
}

function* insertTempoOciosidade(action) {
  try {
    const result = yield call(
      apiPesquisa.insertTempoOciosidadeByPesquisaId,
      action.payload,
    );


    if (result.status === 200) {

      yield put(
        actions.updateTempoReiniciaTotem({
          id: result.data.id,
          pesquisa_id: result.data.pesquisa_id,
          tempo: result.data.tempo,
        }),
      );
    }
  } catch (e) {
    yield console.log(e);
  }
}

function* updateConfiguracaoQuarentena(action) {
  try {
    const result = yield call(
      apiPesquisa.updateConfiguracaoQuarentena,
      action.payload,
    );


    if (result.success) {
      yield put(
        actions.resultadoConfiguracaoQuarentena({
          quarentenaResult: result.success,
        }),
      );
      yield put(actions.atualizaConfiguracaoQuarentena(result.quarentena));

      toastr.success('Configuração inserida com sucesso');
    }
  } catch (e) {
    yield put(actions.erroPesquisa('Erro ao configurar quarentena'));
    yield console.log(e);
  }
}

function* alterarNomeLista(action) {
  try {

    const result = yield call(apiContato.alterarNomeLista, action.payload);

    if (result.data.success) {
      yield put(
        actions.atualizaNomeLista({

          id: action.payload.id,
          nome: action.payload.nome.trim(),

        }),
      );
      toastr.success('Nome da lista alterado com sucesso.');
    } else {
      throw new Error(result.data.message || 'Erro ao alterar nome da lista.');
    }
  } catch (e) {

    toastr.error(e.message || 'Erro desconhecido.');
  }
}

function* arquivoModificado(action) {
  try {

    const result = yield call(apiContato.removerListaFiltro, action.payload.id);

    if (result.success) {
      yield put(
        actions.listaModificada(action.payload),
      );
    } else {
      throw new Error('Erro ao remover lista.');
    }
  } catch (e) {

    toastr.error(e.message || 'Erro desconhecido.');
  }
}


// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield takeLatest(ORDENAR_PESQUISA, ordenarPesquisa);
  yield takeLatest(INSERIR_PESQUISA, inserirPesquisa);
  yield takeLatest(DUPLICAR_PESQUISA, duplicarPesquisa);
  yield takeLatest(INSERIR_PAGINA, inserirPagina);
  yield takeLatest(LISTA_PESQUISAS, getPesquisas);
  yield takeLatest(BUSCA_PESQUISA_INDIVIDUAL, getPesquisaIndvidual);
  yield takeLatest(INSERIR_PERGUNTA, inserirPergunta);
  yield takeLatest(ALTERAR_PESQUISA, alterarPesquisa);
  yield takeLatest(CRIA_QUESTIONARIO, criaQuestionario);
  yield takeLatest(ALTERAR_PERGUNTA, alterarPergunta);
  yield takeLatest(INSERIR_CONFIGURACAO_EMAIL, inserirConfiguracaoEmail);
  yield takeLatest(ALTERAR_CONFIGURACAO_EMAIL, alterarConfiguracaoEmail);
  yield takeLatest(BUSCA_LISTA, buscaLista);
  yield takeLatest(INSERIR_LISTA, inserirLista);
  yield takeLatest(INSERIR_LISTA_ARQUIVO, inserirListaArquivo);
  yield takeLatest(PROCESSAR_LISTA, processarLista);
  yield takeLatest(INSERIR_LISTA_MANUAL, adicionarListaManual);
  yield takeLatest(ALTERAR_PAGINA_AGRADECIMENTO, alterarPaginaAgradecimento);
  yield takeLatest(
    ALTERAR_DATA_PAGINA_AGRADECIMENTO,
    alterarDataPaginaAgradecimento,
  );
  yield takeLatest(INSERIR_LISTA_ENVIO, inserirListaEnvio);
  yield takeLatest(ATUALIZAR_TOTAL_CONTATOS_ENVIO, atualizarTotalContatosEnvio);
  yield takeLatest(INSERIR_PERSONALIZAR_LAYOUT, inserirPersonalizarLayout);
  yield takeLatest(ALTERAR_PERSONALIZAR_LAYOUT, alterarPersonalizarLayout);
  yield takeLatest(IMPORTA_QUESTIONARIO_MODELO, getModelo);
  yield takeLatest(ATUALIZA_NOME_ENVIO, alteraNomeEnvio);
  yield takeLatest(AGENDA_ENVIO_RELATORIO, agendaEnvioRelatorio);
  yield takeLatest(ALTERA_INTERVALO_ENVIO_API, alteraIntervaloEnvioApi);
  yield takeLatest(FINALIZA_PESQUISA_API, finalizaPesquisaApi);
  yield takeLatest(ALTERA_PERFIL_GERENCIAMENTO, alteraNomeEmailUsuario);
  yield takeLatest(ALTERA_SENHA_USUARIO, alteraSenhaUsuario);
  yield takeLatest(INSERIR_TRADUCAO_PERGUNTA, inserirTraducaoPergunta);
  yield takeLatest(INSERIR_TRADUCAO_CONVITE, inserirTraducaoConvite);
  yield takeLatest(PERGUNTA_REMOVIDA, perguntaQuestionarioRemovida);
  yield takeLatest(
    INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO,
    inserirTraducaoPaginaAgradecimento,
  );
  yield takeLatest(REMOVER_LIMITE_RESPOSTA, removeLimiteRespostaPesquisa);
  yield takeLatest(NOVO_LIMITE_RESPOSTA, atualizaNovoLimiteRespostaPesquisa);
  yield takeLatest(INSERT_LIMITE_RESPOSTA, insertLimiteResposta);
  yield takeLatest(INSERT_TEMPO_OCIOSIDADE, insertTempoOciosidade);
  yield takeLatest(UPDATE_CONFIGURACAO_QUARENTENA, updateConfiguracaoQuarentena);
  yield takeLatest(ALTERAR_NOME_LISTA, alterarNomeLista);
  yield takeLatest(ARQUIVO_MODIFICADO, arquivoModificado);
}
