enum PesquisaStatusEnum {
  EM_CONSTRUÇAO = 1,
  AGENDADA = 2,
  EM_COLETA = 3,
  PAUSADA = 4,
  EXCLUIDA = 5,
  AGUARDANDO_INTEGRAÇAO = 6,
  CONCLUIDA = 7,
}

export default PesquisaStatusEnum;
