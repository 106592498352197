import React, { ChangeEvent, useRef } from 'react';
import styles from './style.module.scss';

type InputContainerWithCounterProps = {
  maxLength?: number;
  value: string;
  onChange: (value: string) => void;
  type?: 'text' | 'textarea';
};

function InputContainerWithCounter({
  value,
  maxLength = 30,
  onChange,
  type = 'text',
}: InputContainerWithCounterProps) {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const val = e.target.value;
    onChange(val.slice(0, maxLength));
  }

  function handleContainerFocus(e: React.FocusEvent<HTMLDivElement, Element>) {
    inputRef.current?.focus();
  }

  const val = value.slice(0, maxLength);

  return (
    <div
      className={`d-flex form-control align-items-end text-primary flex-row ${styles.container}`}
      tabIndex={-1}
      onFocus={handleContainerFocus}
    >
      {type === 'text' ? (
        <input
          ref={inputRef as React.RefObject<HTMLInputElement>}
          value={val}
          onChange={handleChange}
        />
      ) : (
        <textarea
          ref={inputRef as React.RefObject<HTMLTextAreaElement>}
          value={val}
          onChange={handleChange}
        />
      )}
      <div data-element="counter">
        {val.length} / {maxLength}
      </div>
    </div>
  );
}

export default InputContainerWithCounter;
