import instance from './instance';

interface CriarCategoriaProps {
  nome: string;
  exemplo: string;
  descricao: string;
}

export const criarCategoria = (data: CriarCategoriaProps): Promise<void> =>
  instance.post('/categorias/criar', data);
