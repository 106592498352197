import {
  ATUALIZA_PESQUISAS,
  LISTA_PESQUISAS,
  INSERIR_PESQUISA,
  INSERIR_PESQUISA_RESULT,
  DUPLICAR_PESQUISA,
  PESQUISA_SUCCESS,
  PESQUISA_ERROR,
  INSERIR_PERGUNTA,
  ALTERAR_PERGUNTA,
  INSERIR_CONFIGURACAO_EMAIL,
  INSERIR_CONFIGURACAO_WIDGET,
  ATUALIZA_CONFIGURACAO_WIDGET,
  INSERIR_WIDGET_STATE,
  ALTERAR_CONFIGURACAO_EMAIL,
  RESULTADO_CONFIGURACAO_EMAIL,
  ALTERAR_PAGINA_AGRADECIMENTO,
  INSERIR_LISTA_ENVIO,
  ATUALIZAR_TOTAL_CONTATOS_ENVIO,
  ALTERAR_AGRADECIMENTO_RESULT,
  ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT,
  ENVIA_EMAIL_TESTE,
  FINALIZA_EMAIL_TESTE,
  INSERIR_PERSONALIZAR_LAYOUT,
  ALTERAR_PERSONALIZAR_LAYOUT,
  ATUALIZA_LAYOUT_PERSONALIZADO,
  IMPORTA_QUESTIONARIO_MODELO,
  ATIVA_LOADER,
  ATUALIZA_LISTA_ENVIO,
  DESATIVA_LOADER,
  ATUALIZAR_PERGUNTA,
  ATUALIZAR_TODAS_PERGUNTAS,
  ATUALIZA_PAGINAS,
  ATUALIZA_PESQUISA_INDIVIDUAL,
  INSERIR_ENVIO,
  ATUALIZA_CONFIGURACAO_EMAIL,
  ATUALIZA_ENVIO_PESQUISA,
  ENVIO_RESULT_SUCCESS,
  CLEAR_STATE,
  UPDATE_IDIOMA,
  INSERIR_IDIOMA,
  REMOVE_IDIOMA_PESQUISA,
  ALTERAR_IDIOMA_PESQUISA,
  UPDATE_LIMITE_RESPOSTA,
  UPDATE_ARRAY_LIMITE_RESPOSTA,
  UPDATE_TEMPO_REINICIA_TOTEM,
  RESULTADO_CONFIGURACAO_QUARENTENA,
  ATUALIZA_CONFIGURACAO_QUARENTENA
} from 'actions/actionTypes';
import AcoesPesquisa from './AcoesPesquisa';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const pesquisa = (state = initialState, action) => {
  const acoesPesquisas = new AcoesPesquisa(state.items);

  switch (action.type) {
    case INSERIR_PESQUISA:
    case DUPLICAR_PESQUISA:
    case IMPORTA_QUESTIONARIO_MODELO:
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
      };
    case INSERIR_PESQUISA_RESULT:
    case ENVIO_RESULT_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        result: action.payload,
      };
    case ALTERAR_AGRADECIMENTO_RESULT:
      return {
        ...state,
        items: acoesPesquisas.atualizaPaginaAgradecimento(
          action.payload.result,
        ),
        loading: true,
        error: null,
        result_id: action.payload.result.id,
        acao: 'alterar-agradecimento',
      };
    case ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT:
      return {
        ...state,
        items: acoesPesquisas.atualizaPaginaAgradecimento(
          action.payload.result,
        ),
        loading: false,
        error: null,
        result_id: action.payload.result.id,
      };
    case PESQUISA_SUCCESS:
    case FINALIZA_EMAIL_TESTE:
      return {
        ...state,
        loading: false,
        error: null,
        result: null,
      };
    case PESQUISA_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ATUALIZA_PESQUISAS:
      return { items: action.payload, loading: true, error: null };
    case ATUALIZA_PESQUISA_INDIVIDUAL:
      return {
        items: acoesPesquisas.atualizaPesquisa(
          action.payload.pesquisa ? action.payload.pesquisa : action.payload,
        ),
        loading: true,
        error: null,
        result: action.payload.pesquisa,
      };
    case INSERIR_IDIOMA: {
      return {
        items: acoesPesquisas.inserirIdioma(action.payload),
        loading: false,
        error: null,
      };
    }
    case ATUALIZAR_PERGUNTA: {
      return {
        items: acoesPesquisas.addPergunta(action.payload),
        loading: false,
        error: null,
      };
    }
    case ATUALIZAR_TODAS_PERGUNTAS: {
      return {
        items: acoesPesquisas.atualizaPerguntas({
          paginas: action.payload?.paginas,
          perguntas: action.payload?.perguntas,
          atualizarIdioma: action.payload?.atualizarIdioma,
          idiomasStateAtualizados: action.payload?.idiomasStateAtualizados
        }),
        loading: false,
        error: null,
      };
    }
    case ATUALIZA_PAGINAS: {
      return {
        items: acoesPesquisas.addPagina(action.payload),
        loading: false,
        error: null,
      };
    }
    case ATUALIZA_LAYOUT_PERSONALIZADO: {
      return {
        items: acoesPesquisas.atualizaPersonalizarLayout(action.payload),
        loading: false,
        error: null,
      };
    }
    case ATUALIZA_CONFIGURACAO_EMAIL: {
      return {
        items: acoesPesquisas.atualizaConfiguracaoEmail(action.payload),
        result: null,
        loading: false,
        error: null,
      };
    }
    case ATUALIZA_CONFIGURACAO_WIDGET:
      return {
        items: acoesPesquisas.atualizaConfiguracaoWidget(action.payload),
        result: null,
        loading: false,
        error: null,
      };
    case INSERIR_WIDGET_STATE:
      return {
        items: acoesPesquisas.insereWidgetState(action.payload),
        loading: false,
        error: null,
      };
    case ATUALIZA_ENVIO_PESQUISA: {
      return {
        items: acoesPesquisas.addOuAtualizaEnvio(action.payload),
        result: null,
        loading: false,
        error: null,
      };
    }
    case ATUALIZA_LISTA_ENVIO: {
      return {
        items: acoesPesquisas.addOuAtualizaListaEnvio(action.payload),
        result: null,
        loading: false,
        error: null,
      };
    }
    case CLEAR_STATE: {
      return {
        items: [],
        loading: false,
        error: null,
      };
    }
    case LISTA_PESQUISAS:
    case INSERIR_PERGUNTA:
    case ALTERAR_PERGUNTA:
    case ALTERAR_PAGINA_AGRADECIMENTO:
    case INSERIR_LISTA_ENVIO:
    case ENVIA_EMAIL_TESTE:
    case ATUALIZAR_TOTAL_CONTATOS_ENVIO:
    case INSERIR_PERSONALIZAR_LAYOUT:
    case ALTERAR_PERSONALIZAR_LAYOUT:
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
      };
    case INSERIR_ENVIO:
      return {
        items: acoesPesquisas.addEnvio(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    case ALTERAR_CONFIGURACAO_EMAIL:
    case INSERIR_CONFIGURACAO_EMAIL:
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
      };

    case INSERIR_CONFIGURACAO_WIDGET:
      return {
        items: acoesPesquisas.atualizaConfiguracaoWidget(action.payload),
        result: null,
        loading: false,
        error: null,
      };

    case RESULTADO_CONFIGURACAO_EMAIL:
      return {
        ...state,
        loading: false,
        error: null,
        result: action.payload,
      };
    case RESULTADO_CONFIGURACAO_QUARENTENA:
      return {
        ...state,
        loading: false,
        error: null,
        result: action.payload,
      };

    case UPDATE_IDIOMA: {
      const updatedValues = state.items.map((_pesquisa) => {
        if (_pesquisa.id === action.payload.idiomaState.pesquisa_id) {
          const pesquisaAtualizada = { ..._pesquisa };
          const indexOfIdioma = pesquisaAtualizada.idiomas.findIndex(
            (idioma) => idioma.id === action.payload.idiomaState.id,
          );
          pesquisaAtualizada.idiomas[indexOfIdioma] =
            action.payload.idiomaState;

          return pesquisaAtualizada;
        }
        return _pesquisa;
      });

      return { ...state, items: updatedValues };
    }

    case ALTERAR_IDIOMA_PESQUISA: {
      const updatedValues = state.items.map((_pesquisa) => {
        const pesquisaAtualizada = { ..._pesquisa };
        if (_pesquisa.id === Number(action.payload.pesquisa_id)) {
          pesquisaAtualizada.idioma_padrao_id = action.payload.idioma_id;

          return pesquisaAtualizada;
        }

        return _pesquisa;
      });
      return {
        ...state,
        items: updatedValues,
        result: { success: true, idioma_id: action.payload.idioma_id },
      };
    }

    case ATIVA_LOADER:
      return { ...state, loading: true };

    case DESATIVA_LOADER:
      return {
        ...state,
        loading: false,
        error: null,
        result: null,
      };
    case REMOVE_IDIOMA_PESQUISA:
      return {
        items: acoesPesquisas.removeIdiomaPesquisa(action.payload),
        loading: false,
        error: null,
        idiomaRemovido: true,
      };
    case UPDATE_LIMITE_RESPOSTA:
      return {
        items: acoesPesquisas.updateLimiteRespostas(action.payload),
        loading: false,
        error: null,
      };
    case UPDATE_ARRAY_LIMITE_RESPOSTA:
      return {
        items: acoesPesquisas.updateArrayLimiteRespostas(action.payload),
        loading: false,
        error: null,
      };
    case UPDATE_TEMPO_REINICIA_TOTEM:
      return {
        items: acoesPesquisas.updateTempoReiniciaTotem(action.payload),
        loading: false,
        error: null,
      };

    case ATUALIZA_CONFIGURACAO_QUARENTENA: {
      return {
        items: acoesPesquisas.atualizaConfiguracaoQuarentena(action.payload),
        result: null,
        loading: false,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default pesquisa;
