import instance from './instance';
import { Pesquisa_personalizar_layout } from '../../entities/pesquisa/pesquisa_personalizar_layout';

interface PesquisaLayoutData {
  id: number;
  msg: string;
  success: boolean;
  result: Pesquisa_personalizar_layout;
}

interface PesquisaLayoutPayload {
  data: PesquisaLayoutData;
}

const alterarPersonalizarLayout = (
  body: Pesquisa_personalizar_layout,
): Promise<PesquisaLayoutPayload> =>
  instance.put('/questionario/personalizar/alterar', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export default alterarPersonalizarLayout;
