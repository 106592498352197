import { Header, HeaderGroup, flexRender } from '@tanstack/react-table';
import TableHeaderSortIcons from 'components/TableHeaderSortIcons';
import { Col, ColProps, Row } from 'reactstrap';
import styles from './styles.module.scss';

interface TableHeaderProps<T> {
  headerGroup: HeaderGroup<T>;
  getColProps?: (header: Header<T, unknown>) => ColProps;
}

function getColumnSize<T>(header: Header<T, unknown>) {
  const mdValues: Record<string, number> = {
    checkbox: 2,
    titulo: 10,
    colSpan: 1,
  };

  const md = mdValues[header.id];

  return { md, sm: md };
}
export function TableHeader<T>({
  headerGroup,
  getColProps = getColumnSize,
}: TableHeaderProps<T>) {
  function sortByColumn(header: Header<T, unknown>) {
    header.column.getToggleSortingHandler();
  }

  return (
    <Row tag="tr" className={styles.tableHeader} key={headerGroup.id}>
      {headerGroup.headers.map((header, i) => {
        const colProps = getColProps?.(header) ?? {};

        return (
          <Col
            tag="th"
            key={header.id}
            className=" text-center pointer pl-2 pr-2"
            {...colProps}
            onClick={() => sortByColumn(header)}
          >
            {header.isPlaceholder ? null : (
              <div
                className="text-primary"
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                <TableHeaderSortIcons
                  sortDirection={header.column.getIsSorted()}
                />
              </div>
            )}
          </Col>
        );
      })}
    </Row>
  );
}
