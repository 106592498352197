import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';
import { FormProvider } from 'contexts/FormContext';

import Loader from 'components/Loader';
import { PrivateRoute } from './PrivateRoute';

const CloseTheLoop = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/pages/PaginaInicialGeral'
    ),
);

const TratativaPesquisa = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/pages/TratativaPesquisa'
    ),
);

const DetalhesTratativa = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/pages/DetalhesTratativa'
    ),
);

const ResponderTratativaConfigEmail = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/ResponderTratativa/ConfigEmail'
    ),
);

const ResponderTratativaResumoEnvio = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/ResponderTratativa/ResumoEnvio'
    ),
);

const EmailTratativaEnviada = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/CloseTheLoop/ResponderTratativa/TudoCerto'
    ),
);

export const rotasCloseTheLoop = (HomeLayout: React.FC) => {
  return {
    path: '/close-the-loop',
    element: (
      <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
        <React.Suspense fallback={<Loader />}>
          <HomeLayout />
        </React.Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <CloseTheLoop />,
      },
      {
        path: ':pesquisaId/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <TratativaPesquisa />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/detalhes/:tratativaId',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <DetalhesTratativa />
          </PrivateRoute>
        ),
      },
      {
        path: 'responder-tratativa/e-mail',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <FormProvider>
              <ResponderTratativaConfigEmail />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: 'responder-tratativa/resumo',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <FormProvider>
              <ResponderTratativaResumoEnvio />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: 'responder-tratativa/tudo-certo',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <EmailTratativaEnviada />
          </PrivateRoute>
        ),
      },
    ],
  };
};
