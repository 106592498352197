import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';
import { Usuario } from '../../entities/auth/usuario';

interface IUsuario extends Usuario {
  email: string;
  nome_organizacao: string;
  iat: number;
  exp: number;
  exibePerguntaSentimento: boolean;
  exibePerguntaEscalaComLegenda?: boolean;
  aumentaCaracteresBateria?: boolean;
}

interface GetNewPayloadUserData {
  token: string;
  payload: IUsuario;
}

interface GetNewPayloadUserPayload {
  data: GetNewPayloadUserData;
}

const getNewPayloadUser = (): Promise<GetNewPayloadUserPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.get('/revalidate-payload/', {
    headers: {
      Authorization: token.toString(),
    },
  });
};

export default getNewPayloadUser;
