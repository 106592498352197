import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface UserDataProps {
  id: number;
  login: string;
}

interface NovoUsuarioData {
  msg: string;
  usuario: UserDataProps;
}

interface NovoUsuarioPayload {
  data: NovoUsuarioData;
}

const inserirNovoUsuario = async (body: {
  nome: string;
  email: string;
  role_id: number;
  restricao?: string;
}): Promise<NovoUsuarioPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.post('/inserir', body, {
    headers: {
      Authorization: token.toString(),
    },
  });
};

export default inserirNovoUsuario;
