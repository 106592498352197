import instance from './instance';

interface ResultadoNpsData {
  NPS: string;
  name: string;
  quantidade_respostas: string;
}

interface ResultadoNps {
  data: ResultadoNpsData[];
}

const buscaResultadoNps = (body: {
  pesquisa_id: number;
  pergunta_id: number;
  filtros?: number[];
  periodo: string;
  data: { startDateRange: Date; endDateRange: Date };
}): Promise<ResultadoNps> => instance.post('/resultado/nps', body);

export default buscaResultadoNps;
