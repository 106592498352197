import React from 'react';

import { FormProvider } from 'contexts/FormContext';
import {
  permissaoTodasRoles,
  permissaoSomenteAdminSistema,
  permissaoTodosUsuarios,
} from 'config/Roles';

import { PrivateRoute } from './PrivateRoute';

const RelatorioErros = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/ImportacaoListagem/Relatorio" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Relatorio'
    ),
);

const TitlebarBenchmark = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Titlebar" */ 'pages/Benchmark/Admin/Benchmark/Titlebar'
    ),
);

const BenchmarkCliente = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Cliente" */ 'pages/Benchmark/Cliente'
    ),
);
const Benchmark = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark" */ 'pages/Benchmark/Admin/Benchmark'
    ),
);

const Questionario = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/Questionario" */ 'pages/Benchmark/Admin/Benchmark/Questionario'
    ),
);

const ImportacaoListagem = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem'
    ),
);

const InformacaoOnda = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/InformacaoOnda" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/InformacaoOnda'
    ),
);

const Arquivo = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/Arquivo" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Arquivo'
    ),
);

const Mapear = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/Mapear" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Mapear'
    ),
);

const Processar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/Processar" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Processar'
    ),
);

const Processando = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/Processando" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Processando'
    ),
);

const ImportacaoResultados = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/ImportacaoListagem/Resultados" */ 'pages/Benchmark/Admin/Benchmark/ImportacaoListagem/Resultados'
    ),
);

const Publicacao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/Benchmark/Publicacao" */ 'pages/Benchmark/Admin/Benchmark/Publicacao'
    ),
);

const ListaBenchmark = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Admin/ListaBenchmark" */ 'pages/Benchmark/Admin/ListaBenchmark'
    ),
);
const Relatorio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Benchmark/Relatorio" */ 'pages/Benchmark/Relatorio'
    ),
);

export const rotasBenchmark = (HomeLayout: React.FC) => {
  return {
    path: '/benchmark',
    element: (
      <PrivateRoute permission={permissaoTodasRoles}>
        <HomeLayout />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <ListaBenchmark />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: 'novo',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <TitlebarBenchmark pagina="novo-benchmark" />
              <Benchmark />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/editar/',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <TitlebarBenchmark pagina="edit-benchmark" />
              <Benchmark />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/questionario/',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Questionario />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/importacao/',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <ImportacaoListagem />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/importacao/onda/:ondaId/informacao/:edit',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <InformacaoOnda />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/importacao/arquivo',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Arquivo />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/importacao/:importacaoId/mapear',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Mapear />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/importacao/:importacaoId/processar',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Processar />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/processando',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Processando />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/importacoes',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <ImportacaoResultados />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/onda/:ondaId/lista/:listaId/relatorio',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <RelatorioErros />
          </PrivateRoute>
        ),
      },
      {
        path: 'listagem',
        element: (
          <PrivateRoute permission={permissaoTodosUsuarios}>
            <FormProvider>
              <BenchmarkCliente />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':id/publicar/',
        element: (
          <PrivateRoute permission={permissaoSomenteAdminSistema}>
            <FormProvider>
              <Publicacao />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: '/benchmark/:id/relatorio/',
        element: (
          <PrivateRoute>
            <Relatorio />
          </PrivateRoute>
        ),
      },
      {
        path: '/benchmark/:id/relatorio/:perguntaId',
        element: (
          <PrivateRoute>
            <Relatorio />
          </PrivateRoute>
        ),
      },
      {
        path: '/benchmark/:id/relatorio/:perguntaId/:empresaId',
        element: (
          <PrivateRoute>
            <Relatorio />
          </PrivateRoute>
        ),
      },
    ],
  };
};
