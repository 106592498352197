import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL_CX_SERVER;

const instance = axios.create({
  baseURL: `${baseURL}/auth`,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default instance;
