import instance from './instance';

interface PaginaInicialPayload {
  success: boolean;
  data: number[];
}

const insertPaginaInicialTotem = (body: {
  pesquisa_id: number;
}): Promise<PaginaInicialPayload> =>
  instance.post('/totem/pagina-inicial/criar', body);

export default insertPaginaInicialTotem;
