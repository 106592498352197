import { useEffect, useMemo, useReducer, useState, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';

import toastr from 'toastr';

import { usePesquisa } from 'hooks';
import { Pergunta } from 'hooks/types';
import TipoPerguntaEnum from 'enums/TipoPerguntaEnum';
import {
  getCategoriasAll,
  getConfiguraçaoById,
  createConfiguraçao,
} from 'services/categorizacao';
import { ConteudoDashboard } from 'components/ConteudoDashboard';
import ButtonControlDisabled, {
  ButtonControlDisabledRef,
} from 'components/Button/ButtonControlDisabled';
import {
  ActionTypes,
  CategorizacaoReducer,
  CategorizationReducerState,
  SelectionState,
  generateSelectionStateFromArray,
} from './utils';

import SelecionarCategorias from './screens/SelecionarCategorias';
import SelecionarPerguntas from './screens/SelecionarPerguntas';
import StepBar from './components/Stepbar';

import styles from './styles.module.scss';

const INITIAL_STATE: CategorizationReducerState = {
  categoriasSelecionadasIds: {},
  perguntasSelecionadasIds: {},
  steps: 2,
  currentStep: 0,
  stepsCompletion: [false, false],
};

interface Categoria {
  id: number;
  nome: string;
  descricao: string;
}

function CategorizacaoIA() {
  const buttonSaveRef = useRef<ButtonControlDisabledRef>(null);
  const buttonNextRef = useRef<ButtonControlDisabledRef>(null);

  const pesquisa = usePesquisa();
  const { pathname } = useLocation();
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [isConfigured, setIsConfigured] = useState<boolean>(false);

  const [
    {
      categoriasSelecionadasIds,
      perguntasSelecionadasIds,
      currentStep,
      stepsCompletion,
    },
    dispatch,
  ] = useReducer(CategorizacaoReducer, INITIAL_STATE);

  const navigate = useNavigate();

  useEffect(() => {
    getConfiguraçaoById(pesquisa.id)
      .then((res) => {
        const { perguntas, categorias } = res;

        if (perguntas.length > 0 && categorias.length > 0) {
          setIsConfigured(true);
        }

        if (perguntas.length > 0) {
          const perguntasSelectionState =
            generateSelectionStateFromArray(perguntas);
          handlePerguntaSelection(perguntasSelectionState);
        }

        if (categorias.length > 0) {
          const categoriasSelectionState =
            generateSelectionStateFromArray(categorias);
          handleCategoriaSelection(categoriasSelectionState);
        }

        dispatch({ type: ActionTypes.CHANGE_STEP, step: 0 });
      })
      .catch((_) => {
        toastr.error(
          'Erro ao buscar configuração de categorização de pesquisa.',
        );
      });
  }, [pesquisa.id]);

  useEffect(() => {
    getCategoriasAll()
      .then(({ data }) => {
        setCategorias(data.categorias);
      })
      .catch((_) => toastr.error('Erro ao buscar categorias.'));
  }, []);

  const perguntas = useMemo(() => {
    return pesquisa.perguntas?.filter(
      (pergunta) => pergunta.tipo_pergunta_id === TipoPerguntaEnum.ABERTA,
    ) as Pergunta[];
  }, [pesquisa]);

  function handlePerguntaSelection(perguntasSelecionadas: SelectionState) {
    dispatch({
      type: ActionTypes.SELECT_PERGUNTAS,
      perguntas: perguntasSelecionadas,
    });
  }
  function handleCategoriaSelection(categoriasSelecionadas: SelectionState) {
    dispatch({
      type: ActionTypes.SELECT_CATEGORIAS,
      categorias: categoriasSelecionadas,
    });
  }

  async function handleNextStep() {
    if (currentStep <= 1) {
      dispatch({ type: ActionTypes.NEXT_STEP });
    } else {
      await handleCreateConfiguracao();
    }
  }

  const handleCreateConfiguracao = async () => {
    buttonSaveRef.current?.disable();
    buttonNextRef.current?.disable();

    try {
      await createConfiguraçao({
        pesquisa_id: pesquisa.id,
        perguntas: Object.keys(perguntasSelecionadasIds).map(Number),
        categorias: Object.keys(categoriasSelecionadasIds).map(Number),
      }).then(() => {
        if (pathname.includes('/categorizacao/editar')) {
          navigate('/categorizacao', { state: { aba: 1 } });
        } else {
          navigate(-1);
        }
      });
    } catch (error) {
      buttonSaveRef.current?.enable();
      buttonNextRef.current?.enable();
      toastr.error('Erro ao configurar categorização.');
    }
  };

  function handlePreviousStep() {
    if (currentStep === 0) {
      if (pathname.includes('/categorizacao/editar')) {
        navigate('/categorizacao', { state: { aba: 1 } });
      } else {
        navigate(-1);
      }
    } else {
      dispatch({ type: ActionTypes.CHANGE_STEP, step: 0 });
    }
  }

  const canProceedToNextStep =
    stepsCompletion[currentStep] || currentStep === 2;

  useEffect(() => {
    if (
      currentStep === 1 &&
      Object.keys(categoriasSelecionadasIds).length > 0
    ) {
      dispatch({ type: ActionTypes.CHANGE_STEP, step: 2 });
    }
  }, [currentStep, categoriasSelecionadasIds]);

  return (
    <>
      <StepBar currentStep={currentStep} onPreviousStep={handlePreviousStep} />
      <Navbar className="border-bottom" color="white" light expand="md">
        <FaArrowLeft
          className="text-primary btn-voltar pointer"
          onClick={() => handlePreviousStep()}
        />
        <NavbarBrand className="ml-3 text-primary">
          <span className="mr-3 header-text title-bar-pesquisa-nome">
            {pesquisa.nome}
          </span>
        </NavbarBrand>

        <div className={styles.options}>
          {isConfigured && (
            <ButtonControlDisabled
              ref={buttonSaveRef}
              color="link"
              className={styles.buttonSave}
              onClick={handleCreateConfiguracao}
            >
              Salvar alterações
            </ButtonControlDisabled>
          )}

          <ButtonControlDisabled
            ref={buttonNextRef}
            color="secondary"
            onClick={handleNextStep}
            disabled={!canProceedToNextStep}
          >
            Avançar <FaArrowRight className="ml-2" size={13} />
          </ButtonControlDisabled>
        </div>
      </Navbar>

      <ConteudoDashboard className="red">
        {currentStep === 0 ? (
          <SelecionarPerguntas
            perguntas={perguntas}
            onPerguntasSelection={handlePerguntaSelection}
            perguntasSelecionadas={perguntasSelecionadasIds}
          />
        ) : (
          <SelecionarCategorias
            categorias={categorias}
            categoriasSelecionadas={categoriasSelecionadasIds}
            onCategoriasSelection={handleCategoriaSelection}
          />
        )}
      </ConteudoDashboard>
    </>
  );
}

export default CategorizacaoIA;
