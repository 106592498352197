import instance from './instance';
import { Lista_filtro_ignorado } from '../../entities/contato/lista_filtro_ignorado';

interface Filtro extends Lista_filtro_ignorado {
  nome_coluna_bd: string;
  nome_personalizado: string;
  organizacao_id: number;
  tipo: number;
}

interface FiltrosLista {
  data: Filtro[];
}
const buscaListaFiltroIgnorado = (
  id?: string | undefined | number,
): Promise<FiltrosLista> => instance.get(`/lista/filtros/ignorado/${id}`);

export default buscaListaFiltroIgnorado;
