/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';
import { Idioma, Pergunta_Idioma } from './types';

interface TraducaoPergunta {
  data: Pergunta_Idioma
  idiomaState: Idioma
  success: boolean
}

const inserirTraducaoPergunta = async (body: {
  id?: number;
  perguntaId: number;
  idiomaId: number;
  titulo: string;
  metadados: any;
}): Promise<TraducaoPergunta | undefined> => {
  try {
    const { data: response, status } = !body?.id
      ? await instance.post('/idioma/pergunta/', {
        pergunta_id: body.perguntaId,
        pesquisa_idioma_id: body.idiomaId,
        titulo: body.titulo,
        metadados: body?.metadados,
      })
      : await instance.put('/idioma/pergunta/', {
        id: body.id,
        pergunta_id: body.perguntaId,
        pesquisa_idioma_id: body.idiomaId,
        titulo: body.titulo,
        metadados: body?.metadados,
      });

    if (status === 201 && response.success && response.data) {
      return response;
    }
    throw new Error(
      `Erro Status: ${status} retorno: ${JSON.stringify(response)}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export default inserirTraducaoPergunta;
