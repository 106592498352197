import TipoPerguntaEnum from 'enums/TipoPerguntaEnum';
import IdiomaStatusEnum from 'enums/IdiomaStatusEnum';

export default class AcoesPesquisa {
  constructor(pesquisas) {
    this.pesquisas = pesquisas;
  }

  setPesquisa(pesquisas) {
    this.pesquisas = pesquisas.sort((prev, next) => prev.id - next.id);
  }

  addPergunta(pergunta) {
    const perguntaArr = [pergunta];

    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === pergunta.pesquisa_id) {
        const idiomasAtualizados = pesquisaItem?.idiomas?.map((idioma) =>
          idioma.pesquisa_idioma_status_id === IdiomaStatusEnum.FINALIZADO
            ? {
              ...idioma,
              pesquisa_idioma_status_id: IdiomaStatusEnum.EM_CONSTRUÇAO,
              status: 'Em construção',
            }
            : idioma,
        );

        const { perguntas } = pesquisaItem;

        if (perguntas) {
          const isCesOrNpsSelect =
            (perguntaArr[0].tipo_pergunta_id === TipoPerguntaEnum.CES ||
              perguntaArr[0].tipo_pergunta_id === TipoPerguntaEnum.NPS) &&
            perguntaArr[0].metadados.outros === false;
          if (
            perguntaArr[0].tipo_pergunta_id === TipoPerguntaEnum.SENTIMENTOS ||
            isCesOrNpsSelect
          ) {
            return {
              ...pesquisaItem,
              perguntas: [...perguntas, ...perguntaArr],
            };
          }
          return {
            ...pesquisaItem,
            perguntas: [...perguntas, ...perguntaArr],
            idiomas: idiomasAtualizados,
          };
        }

        const paginas = pesquisaItem.paginas
          ? pesquisaItem.paginas
          : [
            {
              id: pergunta.pagina_id,
              pesquisa_id: pesquisaItem.id,
              numero: 1,
            },
          ];
        return {
          ...pesquisaItem,
          perguntas: [...perguntaArr],
          paginas,
        };
      }
      return pesquisaItem;
    });
    this.setPesquisa(pesquisas);
    return this.pesquisas;
  }

  atualizaPerguntas({
    paginas = [],
    perguntas = [],
    atualizarIdioma,
    idiomasStateAtualizados = null,
  }) {
    let pesquisaId = 0;

    if (perguntas.length > 0) {
      const [perguntaHead] = perguntas;
      pesquisaId = perguntaHead.pesquisa_id;
    } else {
      const url = new URL(window.location.href);

      pesquisaId = Number(url.href.split('/')[4]);
    }

    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (itemPesquisa.id === pesquisaId) {
        const idiomasAtualizados = itemPesquisa?.idiomas?.map((idioma) =>
          idioma.pesquisa_idioma_status_id === IdiomaStatusEnum.FINALIZADO
            ? {
              ...idioma,
              pesquisa_idioma_status_id: IdiomaStatusEnum.EM_CONSTRUÇAO,
              status: 'Em construção',
            }
            : idioma,
        );
        if (atualizarIdioma) {
          return {
            ...itemPesquisa,
            perguntas:
              perguntas.length > 0
                ? perguntas.sort((prev, next) => prev.ordem - next.ordem)
                : null,
            paginas: perguntas.length > 0 ? paginas : null,
            idiomas: idiomasAtualizados,
          };
        }
        if (idiomasStateAtualizados) {
          return {
            ...itemPesquisa,
            perguntas:
              perguntas.length > 0
                ? perguntas.sort((prev, next) => prev.ordem - next.ordem)
                : null,
            paginas: perguntas.length > 0 ? paginas : null,
            idiomas: idiomasStateAtualizados,
          };
        }
        return {
          ...itemPesquisa,
          perguntas:
            perguntas.length > 0
              ? perguntas.sort((prev, next) => prev.ordem - next.ordem)
              : null,
          paginas: perguntas.length > 0 ? paginas : null,
        };
      }
      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  addPagina({ id, numero, pesquisa_id }) {
    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (itemPesquisa.id === pesquisa_id) {
        const { paginas } = itemPesquisa;
        paginas.push({
          id,
          pesquisa_id,
          numero,
          perguntas: [],
        });
        return {
          ...itemPesquisa,
          paginas: paginas
            .sort((prev, next) => prev.numero - next.numero)
            .filter(Boolean),
        };
      }
      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  addPesquisa(novaPesquisa) {
    this.pesquisas.push(novaPesquisa);
    const novasPesquisas = this.pesquisas;

    this.setPesquisa(novasPesquisas);

    return this.pesquisas;
  }

  mutaPesquisa(dadosPesquisa) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === dadosPesquisa.id) {
        return { ...pesquisaItem, ...dadosPesquisa };
      }
      return pesquisaItem;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaPesquisa(dadosPesquisa) {
    if (dadosPesquisa.status_id === 5) {
      return this.removePesquisa(dadosPesquisa);
    }
    const pesquisaExistente = this.pesquisas.find(
      (pesquisaFind) => pesquisaFind.id === dadosPesquisa.id,
    );

    if (!pesquisaExistente) {
      return this.addPesquisa(dadosPesquisa);
    }
    return this.mutaPesquisa(dadosPesquisa);
  }

  removePesquisa(dadosPesquisa) {
    const pesquisas = this.pesquisas.filter(
      (pesquisaItem) => dadosPesquisa.id !== pesquisaItem.id,
    );

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaPaginaAgradecimento(paginaAgradecimento) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (paginaAgradecimento.pesquisa_id === pesquisaItem.id) {
        return {
          ...pesquisaItem,
          pagina_agradecimento: [paginaAgradecimento],
        };
      }
      return pesquisaItem;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaPersonalizarLayout(layoutAtualizado) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (layoutAtualizado.pesquisa_id === pesquisaItem.id) {
        const personalizarLayout = pesquisaItem.personalizar_layout;
        if (!personalizarLayout) {
          return {
            ...pesquisaItem,
            personalizar_layout: [layoutAtualizado],
          };
        }

        const layoutExistente = personalizarLayout.find(
          (layout) => layout.id === layoutAtualizado.id,
        );

        if (layoutExistente) {
          const novoPersonalizarLayout = personalizarLayout.map((layout) => {
            if (layout.id === layoutAtualizado.id) {
              return {
                ...layout,
                ...layoutAtualizado,
              };
            }
            return layout;
          });

          return {
            ...pesquisaItem,
            personalizar_layout: novoPersonalizarLayout,
          };
        }

        personalizarLayout.push(layoutAtualizado);
        return {
          ...pesquisaItem,
          personalizar_layout: personalizarLayout,
        };
      }
      return pesquisaItem;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaConfiguracaoEmail(emailConf) {

    const pesquisas = this.pesquisas.map((pesquisaItem) => {

      if (pesquisaItem.id === parseInt(emailConf.pesquisa_id, 10)) {
        const configuracoesEmail = pesquisaItem.configuracoes_email;
        if (!Array.isArray(configuracoesEmail)) {
          return {
            ...pesquisaItem,
            configuracoes_email: [emailConf],
          };
        }
        const configAtual = configuracoesEmail.find(
          (confEmail) =>
            confEmail.envio_id === parseInt(emailConf.envio_id, 10),
        );

        if (configAtual) {
          return {
            ...pesquisaItem,
            configuracoes_email: configuracoesEmail.map((confMap) => {
              if (confMap.envio_id === parseInt(emailConf.envio_id, 10)) {
                return {
                  ...confMap,
                  ...emailConf,
                };
              }
              return confMap;
            }),
          };
        }
        configuracoesEmail.push(emailConf);
        return {
          ...pesquisaItem,
          configuracoes_email: configuracoesEmail,
        };
      }
      return pesquisaItem;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaConfiguracaoWidget(widgetConf) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === parseInt(widgetConf.pesquisa_id, 10)) {
        const configuracoesWidget = pesquisaItem.widget;

        if (!Array.isArray(configuracoesWidget)) {
          return {
            ...pesquisaItem,
            widget: [widgetConf],
          };
        }
        const configAtual = configuracoesWidget.find(
          (confWidget) =>
            confWidget.pesquisa_id === parseInt(widgetConf.pesquisa_id, 10),
        );

        if (configAtual) {
          return {
            ...pesquisaItem,
            widget: configuracoesWidget.map((confMap) => {
              if (
                confMap.pesquisa_id === parseInt(widgetConf.pesquisa_id, 10)
              ) {
                return {
                  ...confMap,
                  ...widgetConf,
                };
              }
              return confMap;
            }),
          };
        }
        configuracoesWidget.push(widgetConf);
        return {
          ...pesquisaItem,
          widget: configuracoesWidget,
        };
      }
      return pesquisaItem;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  insereWidgetState(widgetConf) {
    if (!this.pesquisa) {
      return [widgetConf];
    }
    return this.pesquisas;
  }

  addEnvio({ envio, idiomasAtualizados }) {
    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (envio.pesquisa_id === itemPesquisa.id) {
        const { envios } = itemPesquisa;

        if (Array.isArray(envios)) {
          envios.push(envio);
          return {
            ...itemPesquisa,
            idiomas: idiomasAtualizados,
            envios,
          };
        }
        return {
          ...itemPesquisa,
          idiomas: idiomasAtualizados,
          envios: [envio],
        };
      }
      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaEnvio(envio) {
    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (envio.pesquisa_id === itemPesquisa.id) {
        const envioAtualizados = itemPesquisa.envios.map((itemEnvio) => {
          if (itemEnvio.id === Number(envio.id)) {
            return {
              ...itemEnvio,
              ...envio,
              id: Number(envio.id),
            };
          }
          return itemEnvio;
        });

        return {
          ...itemPesquisa,
          envios: envioAtualizados,
        };
      }
      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  addOuAtualizaEnvio({ envio, configuracaoEmail, idiomasAtualizados }) {
    let hasEnvio = false;
    this.pesquisas.forEach((pesquisaFind) => {
      if (pesquisaFind.id === envio.pesquisa_id) {
        if (pesquisaFind.envios?.length > 0) {
          pesquisaFind.envios.forEach((itemEnvio) => {
            if (itemEnvio.id === Number(envio.id)) {
              hasEnvio = true;
            }
          });
        }
      }
    });
    if (configuracaoEmail) {
      this.atualizaConfiguracaoEmail(configuracaoEmail);
    }
    if (hasEnvio) {
      return this.atualizaEnvio(envio);
    }
    return this.addEnvio({ envio, idiomasAtualizados });
  }

  addListaEnvio({ lista, pesquisaId, envioId }) {
    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (itemPesquisa.id === pesquisaId) {
        const envios = itemPesquisa.envios.map((envioItem) => {
          if (Number(envioItem.id) === Number(envioId)) {
            return {
              ...envioItem,
              listas: lista,
            };
          }
          return envioItem;
        });
        return {
          ...itemPesquisa,
          envios,
        };
      }

      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  atualizaListaEnvio({ lista, pesquisaId, envioId }) {
    const pesquisas = this.pesquisas.map((itemPesquisa) => {
      if (itemPesquisa.id === pesquisaId) {
        const envios = itemPesquisa.envios.map((envioItem) => {
          if (envioItem.id === envioId) {
            const listaEnvio = envioItem?.listas;

            const listaUpdated = listaEnvio.map((listaItem) => {
              const listaFind = lista.find(
                (listaF) => listaF.id === listaItem.id,
              );

              if (listaFind) {
                return {
                  ...listaItem,
                  ...listaFind,
                };
              }
              return listaItem;
            });

            return {
              ...envioItem,
              listas: listaUpdated,
            };
          }
          return envioItem;
        });
        return {
          ...itemPesquisa,
          envios,
        };
      }

      return itemPesquisa;
    });

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  addOuAtualizaListaEnvio({ lista, pesquisaId, envioId }) {
    let hasListaEnvio = false;

    const pesquisaAlvo = this.pesquisas.find(
      (pesquisaItem) => pesquisaItem.id === pesquisaId,
    );

    const envioAlvo = pesquisaAlvo.envios.find(
      (envioItem) => envioItem.id === envioId,
    );

    if (envioAlvo?.listas) {
      const listasId = lista.map((listaParms) => listaParms.id);

      envioAlvo.listas.forEach((listaItem) => {
        if (listasId.includes(listaItem.id)) {
          hasListaEnvio = true;
        }
      });
    }

    if (hasListaEnvio) {
      return this.atualizaListaEnvio({ lista, pesquisaId, envioId });
    }
    return this.addListaEnvio({ lista, pesquisaId, envioId });
  }

  inserirIdioma(idioma) {
    const idiomaArr = [idioma];

    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === idioma.pesquisa_id) {
        const { idiomas } = pesquisaItem;
        if (idiomas) {
          return {
            ...pesquisaItem,
            idiomas: [...idiomas, ...idiomaArr],
          };
        }
        return {
          ...pesquisaItem,
          idiomas: [...idiomaArr],
        };
      }
      return pesquisaItem;
    });
    this.setPesquisa(pesquisas);
    return this.pesquisas;
  }

  removeIdiomaPesquisa({ pesquisa_id, idioma_id }) {
    const pesquisaAlvo = this.pesquisas.filter(
      (itemPesquisa) => itemPesquisa.id === Number(pesquisa_id),
    );
    const pesquisaPosition = this.pesquisas.indexOf(pesquisaAlvo[0]);
    const idiomaRemovido = pesquisaAlvo[0].idiomas.find(
      (idioma) => idioma.id === idioma_id,
    );

    this.pesquisas[pesquisaPosition].idiomas.splice(
      this.pesquisas[pesquisaPosition].idiomas.indexOf(idiomaRemovido),
      1,
    );
    const { pesquisas } = this;

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  updateLimiteRespostas(payload) {
    const pesquisaAlvo = this.pesquisas.filter(
      (itemPesquisa) => itemPesquisa.id === Number(payload.pesquisa_id),
    );
    delete payload.pesquisa_id
    const pesquisaPosition = this.pesquisas.indexOf(pesquisaAlvo[0]);


    this.pesquisas[pesquisaPosition].limite_resposta[0] =
      { ...this.pesquisas[pesquisaPosition].limite_resposta[0], ...payload }
    const { pesquisas } = this;

    this.setPesquisa(pesquisas);

    return this.pesquisas;
  }

  updateArrayLimiteRespostas(limiteRespostaConf) {
    const limiteArr = [limiteRespostaConf];

    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === limiteRespostaConf.pesquisa_id) {
        return {
          ...pesquisaItem,
          limite_resposta: [...limiteArr],
        };
      }
      return pesquisaItem;
    });
    this.setPesquisa(pesquisas);
    return this.pesquisas;
  }
  updateTempoReiniciaTotem(payload) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {
      if (pesquisaItem.id === Number(payload.pesquisa_id)) {
        return {
          ...pesquisaItem,
          tempo_reiniciar_totem: payload.tempo
        };
      }
      return pesquisaItem;
    });
    this.setPesquisa(pesquisas);
    return this.pesquisas;
  }

  atualizaConfiguracaoQuarentena(payload) {
    const pesquisas = this.pesquisas.map((pesquisaItem) => {

      if (pesquisaItem.id === Number(payload[0].pesquisa_id)) {
        return {
          ...pesquisaItem,
          quarentena: payload
        };
      }
      return pesquisaItem;
    });
    this.setPesquisa(pesquisas);
    return this.pesquisas;
  }

}
