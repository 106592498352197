import instance from './instance';

interface IExcluirRespondentes {
  success: boolean;
  result: { lista_id: number; total_contatos: number };
}

const excluirRespondentesLista = async (
  respondentes: number[],
  listaId: number,
): Promise<IExcluirRespondentes> => {
  const result = await instance.post(`/respondentes/excluir/${listaId}`, {
    respondentes,
  });

  return result.data;
};
export default excluirRespondentesLista;
