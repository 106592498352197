import instance from './instance';

interface LimiteResposta {
  id: number;
  pesquisa_id: string;
  link: string;
}

const getLinkExterno = async (
  pesquisa_id: string | number,
): Promise<LimiteResposta> => {
  const { data: response } = await instance.get(`/link/url/${pesquisa_id}/`);
  return response.data;
};

export default getLinkExterno;
