import instance from './instance';

interface RemoverListaData {
  lista_id: number;
  success: boolean;
}

interface RemoverListaPayload {
  data: RemoverListaData;
  status: number;
}

interface RemoverListaProps {
  data: { lista_id: number };
}

const removerLista = (body: RemoverListaProps): Promise<RemoverListaPayload> =>
  instance.delete('/lista/remover', body);

export default removerLista;
