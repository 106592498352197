import jwt from 'jsonwebtoken';

import LocalStorageEnum from 'enums/LocalStorageEnum';

const atualizaLocalStoreAuth = (token: string) => {
  const jsonToken = jwt.decode(token);
  localStorage.setItem(LocalStorageEnum.TOKEN, `Bearer ${token}`);
  localStorage.setItem(LocalStorageEnum.PAYLOAD, JSON.stringify(jsonToken));
};
export default atualizaLocalStoreAuth;
