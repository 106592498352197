import LocalStorageEnum from 'enums/LocalStorageEnum';
import TipoRolesEnum from 'enums/TipoRolesEnum';
import RolesUsuariosSistemaEnum from 'enums/RolesUsuariosSistemaEnum';

type RolesType = {
  [key: number]: TipoRolesEnum;
};

const roles: RolesType = {
  [RolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO]: TipoRolesEnum.ADMIN_ORGANIZACAO,
  [RolesUsuariosSistemaEnum.ADMIN_SISTEMA]: TipoRolesEnum.ADMIN_SISTEMA,
  [RolesUsuariosSistemaEnum.COLABORADOR]: TipoRolesEnum.COLABORADOR,
  [RolesUsuariosSistemaEnum.VISUALIZADOR]: TipoRolesEnum.VISUALIZADOR,
};

export const getRoleUsuario = (): TipoRolesEnum | undefined => {
  const localStorageData = JSON.parse(
    localStorage.getItem(LocalStorageEnum.PAYLOAD) || '{}',
  );

  if (!localStorageData?.role_id) {
    return undefined;
  }

  return roles[localStorageData.role_id] || undefined;
};
