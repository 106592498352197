import instance from './instance';
import { PesquisaObject } from './types';

interface FinalizaPesquisaData {
  id: number;
  success: boolean;
  result: PesquisaObject;
}

interface FinalizaPesquisaPayload {
  data: FinalizaPesquisaData;
}

const finalizaPesquisaApi = (body: {
  id: number;
  envioId: number;
}): Promise<FinalizaPesquisaPayload> => instance.put('/finalizar', body);

export default finalizaPesquisaApi;
