import { ReactNode, createContext, useContext, useReducer } from 'react';
import { GenericObject } from 'types/utilityTypes';

type ValueType =
  | string
  | number
  | boolean
  | null
  | { [x: string | number]: ValueType }
  | Array<ValueType>;

interface PersistedStateContextData {
  setValue(key: string, value: unknown): void;
  getValue(key: string): ValueType | undefined;
  removeValue(key: string): void;
  clearStore(): void;
  getStore(): void;
}

const PersistedStateContext = createContext<PersistedStateContextData>(
  {} as PersistedStateContextData,
);

interface PersistedStateProviderProps {
  children?: ReactNode;
}

type PersistedStateActions =
  | { type: 'setValue'; key: string; value: ValueType }
  | { type: 'removeValue'; key: string }
  | { type: 'clearStore' };

function persistedStateReducer(
  state: GenericObject = {},
  action: PersistedStateActions,
) {
  switch (action.type) {
    case 'clearStore':
      return {};

    case 'removeValue': {
      const keyToDelete = action.key;
      if (!Object.hasOwn(state, keyToDelete)) return state;

      const newState: GenericObject = { ...state };
      delete newState[keyToDelete];
      return newState;
    }

    case 'setValue': {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}

export function PersistedStateProvider({
  children,
}: PersistedStateProviderProps) {
  const [store, dispatch] = useReducer(
    persistedStateReducer,
    {} as GenericObject,
  );

  const setValue = (key: string, value: ValueType) => {
    dispatch({ type: 'setValue', key, value });
  };

  const removeValue = (key: string) => {
    dispatch({ type: 'removeValue', key });
  };

  const getValue = (key: string): ValueType | undefined => {
    return store[key];
  };

  const getStore = () => {
    return store;
  };

  const clearStore = () => {
    dispatch({ type: 'clearStore' });
  };

  return (
    <PersistedStateContext.Provider
      value={{ getStore, getValue, clearStore, setValue, removeValue }}
    >
      {children}
    </PersistedStateContext.Provider>
  );
}

export function usePersistedState() {
  const persistedContext = useContext(PersistedStateContext);
  if (persistedContext == null) {
    throw new Error('Erro ao recuperar estado persistido!');
  }

  return persistedContext;
}
