import instance from './instance';

interface UpdateInfoContatoData {
  success: boolean;
}

interface UpdateInfoContatoPayload {
  data: UpdateInfoContatoData;
}

interface UpdateInfoContatoProps {
  respondente_id: number;
  alteracoes: {
    campo: string;
    valorAntigo: string;
    valorNovo: string;
    campo_personalizado: string;
    tipo: number;
  }[];
}

const updateInfoContato = (
  body: UpdateInfoContatoProps,
): Promise<UpdateInfoContatoPayload> => instance.post('/update', body);

export default updateInfoContato;
