import {
  INSERIR_LISTA,
  INSERIR_LISTA_ARQUIVO,
  INSERIR_LISTA_MANUAL,
  ALTERAR_LISTA,
  INSERIR_LISTA_RESULT,
  PROCESSAR_LISTA,
  PROCESSAR_LISTA_RESULT,
  BUSCA_LISTA,
  CONTATO_SUCCESS,
  CONTATO_ERROR,
  ATUALIZA_LISTA_INDIVIDUAL,
  INCREMENTA_LISTA_INDIVIDUAL,
  BUSCA_LISTA_RESULT,
  REMOVE_LISTA_CONTATO,
  CLEAR_STATE,
  ATIVA_LOADER_CONTATOS,
  DESATIVA_LOADER_CONTATOS,
  ATUALIZA_NOME_LISTA,
  AVANCAR_LISTA_EXISTENTE,
  PRIMEIROS_CONTATOS_LISTA_VALIDADOS,
  PRIMEIROS_CONTATOS_LISTA_ERRO,
  EXCLUIR_CONTATO,
  RETOMAR_CONTATO,
  LISTA_MODIFICADA,
} from 'actions/actionTypes';
import AcoesContato from './acoesContato';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const contato = (state = initialState, action) => {
  const contatoAcoes = new AcoesContato(state.items);

  switch (action.type) {
    case CONTATO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        result: null,
      };
    case CONTATO_ERROR:
      return { ...state, loading: false, error: action.payload };
    case PROCESSAR_LISTA_RESULT:
    case INSERIR_LISTA_RESULT:
      return {
        ...state, loading: true, error: null, result: action.payload,
      };
    case ATUALIZA_LISTA_INDIVIDUAL: {
      return {
        ...state,
        items: contatoAcoes.addOuAtualizaContatos(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case EXCLUIR_CONTATO: {
      return {
        ...state,
        items: contatoAcoes.atualizaTotalContatosExcluir(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case RETOMAR_CONTATO: {
      return {
        ...state,
        items: contatoAcoes.atualizaTotalContatosRetomar(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case LISTA_MODIFICADA: {
      return {
        ...state,
        items: contatoAcoes.listaModificada(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case INCREMENTA_LISTA_INDIVIDUAL: {
      return {
        ...state,
        items: contatoAcoes.incrementaContatos(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case REMOVE_LISTA_CONTATO: {
      return {
        ...state,
        items: contatoAcoes.removeListaContato(action.payload),
        loading: false,
        error: null,
        result: null,
      };
    }
    case BUSCA_LISTA_RESULT:
      return {
        ...state,
        items: action.payload,
        loading: true,
        error: null,
        result: null,
      };
    case ATUALIZA_NOME_LISTA: {

      return {
        ...state,
        items: state.items.map(lista =>
          lista.id === Number(action.payload.id)
            ? { ...lista, nome: action.payload.nome }
            : lista
        ),
        loading: false,
        error: null,
        result: null,
      };
    }
    case CLEAR_STATE: {
      return {
        items: [],
        loading: false,
        error: null,
      };
    }
    case BUSCA_LISTA:
    case INSERIR_LISTA:
    case INSERIR_LISTA_ARQUIVO:
    case INSERIR_LISTA_MANUAL:
    case PROCESSAR_LISTA:
    case ALTERAR_LISTA:
      return {
        ...state, loading: true, error: null, result: null,
      };
    case ATIVA_LOADER_CONTATOS:
      return { ...state, loading: true };

    case DESATIVA_LOADER_CONTATOS:
      return {
        ...state, loading: false, error: null, result: null,
      };

    case AVANCAR_LISTA_EXISTENTE:
      return { ...state, result: { acao: 'avancar-lista-existente' } }

    case PRIMEIROS_CONTATOS_LISTA_VALIDADOS:
      return { ...state, result: { acao: 'lista-primeiros-contatos-validados' } }

    case PRIMEIROS_CONTATOS_LISTA_ERRO:
      return { ...state, result: { acao: 'lista-primeiros-contatos-erro' } }

    default:
      return state;
  }
};

export default contato;
