import instance from './instance';

export type QuarentenaConfiguracao = {
  pesquisa_id: number;
  ignorar_quarentena: boolean;
  ativa: number;
  tipo?: number;
  dias?: number;
};

export const updateConfiguracaoQuarentena = async (
  data: QuarentenaConfiguracao,
): Promise<QuarentenaConfiguracao> => {
  const response = await instance.post('/quarentena/configuracao', data);

  return response.data;
};
