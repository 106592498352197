import FileSaver from 'file-saver';
import instance from './instance';

const downloadRegistrosAtualizados = (
  nomeDoArquivo: string,
  idLista: number,
  tipoLista: number,
) =>
  instance
    .get(
      `/download-registros-atualizados/${idLista}/${tipoLista}/${nomeDoArquivo}`,
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      FileSaver.saveAs(blob, nomeDoArquivo);
    });

export default downloadRegistrosAtualizados;
