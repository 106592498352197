import instance from './instance';
import { Envio } from './types';

interface CancelarEnvioData {
  success: boolean;
  result: Envio[];
}

interface CancelarEnvioPayload {
  data: CancelarEnvioData;
}

const cancelarEnvio = async (body: {
  id: number;
}): Promise<CancelarEnvioPayload> => instance.put('/envio/cancelar', body);

export default cancelarEnvio;
