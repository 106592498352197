import axios from 'axios';

import LocalStorageEnum from 'enums/LocalStorageEnum';

const baseURL = process.env.REACT_APP_BASE_URL_CX_SERVER;

const apiInstancia = (url: string) => {
  const instancia = axios.create({
    baseURL: `${baseURL}/${url}`,
  });

  instancia.interceptors.request.use(
    (config) => {
      const novaConfig = config;
      const token = localStorage.getItem(LocalStorageEnum.TOKEN);

      if (token && novaConfig.headers) {
        novaConfig.headers.Authorization = token;
      }
      return novaConfig;
    },

    (error) => Promise.reject(error),
  );
  return instancia;
};

export default apiInstancia;
