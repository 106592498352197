import instance from './instance';

interface LimiteResposta {
  pesquisa_id: string;
  ativo?: boolean;
  limite?: number;
  tempo?: number;
}

const getLimiteRespostaByPesquisaId = async (
  pesquisa_id?: string,
): Promise<LimiteResposta | undefined> => {
  const { data: response } = await instance.get(
    `/link/limite-respostas/${pesquisa_id}/`,
  );

  return response.data;
};

export default getLimiteRespostaByPesquisaId;
