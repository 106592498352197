import instance from './instance';
import { Envio_configuracao_email } from '../../entities/pesquisa/envio_configuracao_email';

interface EmailData {
  email: Envio_configuracao_email;
  success: boolean;
}

interface EmailPayload {
  data: EmailData;
}

const alterarConfiguracaoEmail = async (
  body: Envio_configuracao_email,
): Promise<EmailPayload> => instance.put('/configuracao/email/alterar', body);

export default alterarConfiguracaoEmail;
