import instance from './instance';

interface DuplicarPesquisasData {
  success: boolean;
  pesquisaId: number;
}

interface DuplicarPesquisasPayload {
  data: DuplicarPesquisasData;
}

const duplicarPesquisa = async (body: {
  nome: string;
  pesquisa_id: number;
  metodo_envio_id: number;
  canal_envio_id: number;
  criador: string;
}): Promise<DuplicarPesquisasPayload> => instance.post('/duplicar', body);

export default duplicarPesquisa;
