import FileSaver from 'file-saver';
import instance from './instance';

const streamDownload = (
  nomeDoArquivo: string,
  idLista: number,
  funcionalidade: string,
) =>
  instance
    .get(`/download/${idLista}/${funcionalidade}/${nomeDoArquivo}`)
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      FileSaver.saveAs(blob, nomeDoArquivo);
    });

export default streamDownload;
