import instance from './instance';

interface IAlterarSenhaPayload {
  erro: boolean;
  msg: string;
}

interface IAlterarSenha {
  data: IAlterarSenhaPayload;
}

const alterarSenha = (
  password: string,
  token: string,
): Promise<IAlterarSenha> =>
  instance.put('/alterar-senha/', {
    password,
    token,
  });

export default alterarSenha;
