import instance from './instance';

interface IAdicionarRespondenteBlocklist {
  success: boolean;
  id: { id: number };
}

const adicionarRespondentesBlocklist = async (
  respondentes: number[],
): Promise<IAdicionarRespondenteBlocklist> => {
  const result = await instance.post('/blocklist/adicionar', { respondentes });

  return result.data;
};
export default adicionarRespondentesBlocklist;
