import instance from './instance';
import { PesquisaObject } from './types';

interface InserirPesquisasData {
  success: boolean;
  pesquisa: PesquisaObject[];
}

interface InserirPesquisasPayload {
  data: InserirPesquisasData;
}

const inserir = async (body: {
  nome: string;
  metodo_envio_id: number;
  canal_envio_id: number;
  idioma_padrao_id: number;
  idioma_questionario_id: number;
}): Promise<InserirPesquisasPayload> => instance.post('/inserir', body);

export default inserir;
