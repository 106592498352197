import instance from './instance';

type CreateConfiguraçaoProps = {
  pesquisa_id: number;
  perguntas: number[];
  categorias: number[];
};

export const createConfiguraçao = (data: CreateConfiguraçaoProps) =>
  instance.post('/configuracao', data);
