import instance from './instance';
import { QrcodeConfiguracao } from '../../entities/pesquisa/qrcode_configuracao';

type AlterarConfiguracaoQrcodeProps = Omit<QrcodeConfiguracao, 'id'>;

const alterarConfiguracaoQrcode = async (
  body: AlterarConfiguracaoQrcodeProps,
): Promise<QrcodeConfiguracao> => instance.put('/qrcode/configuracao', body);

export default alterarConfiguracaoQrcode;
