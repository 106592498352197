import instance from './instance';
import { Envio } from './types';

interface AgendarEnvioData {
  success: boolean;
  result?: Envio;
  configuracaoHorario?: boolean;
  dataPossivel?: Date;
  msg?: string;
}

interface AgendarEnvioPayload {
  data: AgendarEnvioData;
}

const agendarEnvio = (body: {
  id: number;
  data_agendamento: Date;
  data_criacao: Date;
  horario_comercial: boolean;
}): Promise<AgendarEnvioPayload> => instance.put('/envio/agendar', body);

export default agendarEnvio;
