import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface AlteraNomeEmailProps {
  senhaAtual: string;
  senhaNova: string;
}

const alterarSenhaApp = ({ senhaAtual, senhaNova }: AlteraNomeEmailProps) => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);

  if (!token) {
    throw new Error('Erro ao alterar a senha');
  }

  return instance.put(
    '/alterar-senha-app/',
    {
      senhaAtual,
      senhaNova,
    },
    {
      headers: {
        Authorization: token.toString(),
      },
    },
  );
};
export default alterarSenhaApp;
