import instance from './instance';

export type QuarentenaConfiguracao = {
  pesquisa_id: number;
  ignorar_quarentenas: boolean;
  ativa: boolean;
  tipo?: number;
  dias?: number;
};

type GetConfiguracaoQuarentena = {
  pesquisa_id: number;
};

export const getConfiguracaoQuarentena = async (
  data: GetConfiguracaoQuarentena,
): Promise<QuarentenaConfiguracao[]> => {
  const response = await instance.post('/quarentena/get-configuracao', data);

  return response.data;
};
