import { BsCaretDownSquareFill, BsCaretUpSquareFill } from 'react-icons/bs';
import { FaSort } from 'react-icons/fa';

const sortIcons = {
  asc: <BsCaretUpSquareFill className="ml-2 text-primary" />,
  desc: <BsCaretDownSquareFill className="ml-2 text-primary" />,
} as const;

type TableHeaderIconsProps = {
  sortDirection: 'asc' | 'desc' | false;
};

function TableHeaderSortIcons({ sortDirection }: TableHeaderIconsProps) {
  return sortDirection ? (
    sortIcons[sortDirection]
  ) : (
    <FaSort className="text-primary" />
  );
}

export default TableHeaderSortIcons;
