import React from 'react';

import Loader from 'components/Loader';
import { Outlet } from 'react-router-dom';

const AdicionarOptout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Optout/Adicionar" */ 'pages/Optout/AdicionarOptout/'
    ),
);

const RemoverOptout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Optout/Remover" */ 'pages/Optout/RemoverOptout/'
    ),
);

export const rotasOptout = {
  path: '/opt-out/close-the-loop',
  element: (
    <React.Suspense fallback={<Loader />}>
      <Outlet />
    </React.Suspense>
  ),
  children: [
    {
      path: 'adicionar/:token',
      element: <AdicionarOptout />,
    },
    {
      path: 'remover/:token',
      element: <RemoverOptout />,
    },
  ],
};
