import { Categoria } from 'entities/organizacao/categoria';
import instance from './instance';
import { Result } from './types';

interface ResultadosData {
  success: boolean;
  result: Result[];
}

interface ResultadosPayload {
  data: ResultadosData;
}

interface CategoriaPesquisa {
  pergunta_id: number;
  categorias: Categoria[];
}

const getResultados = (body: {
  pesquisa_id: number;
  filtros?: number[];
  categorias?: CategoriaPesquisa;
  data_resposta: Date;
}): Promise<ResultadosPayload> => instance.post('/resultado', body);

export default getResultados;
