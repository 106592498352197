import instance from './instance';

interface Idioma {
  idioma_id: number;
  pesquisa_id: number;
  pesquisa_idioma_status_id: number;
}

const getIdiomasById = async (
  pesquisa_id: number,
): Promise<Idioma[] | [] | undefined> => {
  try {
    const { data: response, status } = await instance.get(
      `/idioma/${pesquisa_id}/`,
    );
    if (status === 200 && response.success && response.data) {
      return response.data;
    }
    throw new Error(
      `Erro Status: ${status} retorno: ${JSON.stringify(response)}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export default getIdiomasById;
