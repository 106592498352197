import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import {
  Listas,
  Pesquisa,
  PesquisaApiObj,
  PaginaAgradecimento,
  Filtro,
} from '../types';

const formPaginaAgradecimento = 'form-pagina-agradecimento';
const formRelatorioPesquisa = 'form-relatorio-pesquisa';

type Filtros = {
  filtros: Filtro[];
};

interface IFormPaginaAgradecimentoStore {
  contato: {
    error?: string;
    items: Listas[];
    loading: boolean;
  };
  form: {
    formPaginaAgradecimento: {
      initial: PaginaAgradecimento;
      values: PaginaAgradecimento;
    };
  };
  pesquisa: {
    error?: string;
    items: Pesquisa[];
    loading: boolean;
    result?: PesquisaApiObj;
  };
}

interface IFormRelatorioPesquisaStore {
  contato: {
    error?: string;
    items: Listas[];
    loading: boolean;
  };
  form: {
    formRelatorioPesquisa: {
      values: Filtros;
    };
  };
  pesquisa: {
    error?: string;
    items: Pesquisa[];
    loading: boolean;
  };
}

const useFormPaginaAgradecimento = ():
  | PaginaAgradecimento
  | Record<string, undefined> => {
  const stateStore = useSelector(
    (store: IFormPaginaAgradecimentoStore) =>
      getFormValues(formPaginaAgradecimento)(store) || {},
  );
  return stateStore;
};

const useFormRelatorioPesquisa = (): Filtros | Record<string, undefined> => {
  const stateStore = useSelector(
    (store: IFormRelatorioPesquisaStore) =>
      getFormValues(formRelatorioPesquisa)(store) || {},
  );
  return stateStore;
};

export { useFormPaginaAgradecimento, useFormRelatorioPesquisa };
