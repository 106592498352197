import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';
import { Usuario } from '../../entities/auth/usuario';

export interface IUsuario extends Usuario {
  admin: boolean;
  ativo: boolean;
  data_alteracao?: Date;
  data_criacao: Date;
  login: string;
  nome: string;
}

interface IUsuarios {
  user: IUsuario;
  success: boolean;
}

interface IListaUsuariosPayload {
  success: true;
  users: IUsuarios[];
}

const buscaListaUsuarios = (): Promise<IListaUsuariosPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.get('/lista-usuarios/', {
    headers: {
      Authorization: token.toString(),
    },
  });
};

export default buscaListaUsuarios;
