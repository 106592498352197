import instance from './instance';
import { FiltrosType, Resposta } from './entities/resultado';

interface RespostasPerguntaAberta {
  respostas: Resposta[];
  qtd_paginas: number;
  current_page: number;
}

const getResultadoTabelaPerguntaAbertaComCategoria = async (body: {
  filtros: FiltrosType;
  pergunta_id: number;
  pagina: number;
  respostas_ids: number[];
}): Promise<RespostasPerguntaAberta> => {
  const result = await instance.post(
    '/resultado/aberta/tabela/categoria',
    body,
  );

  return result.data;
};

export default getResultadoTabelaPerguntaAbertaComCategoria;
