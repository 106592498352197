import instance from './instance';
import { Lista } from '../../entities/contato/lista';
import { Lista_erro } from '../../entities/contato/lista_erro';

interface Listas extends Lista {
  lista_erros?: Lista_erro[];
  metodo_importacao: string;
  status: string;
}

interface ListaData {
  result: Listas[];
  success: boolean;
}

interface ListasPayload {
  data: ListaData;
}

const buscaListas = (): Promise<ListasPayload> => instance.get('/listas');

export default buscaListas;
