import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface AlteraNomeEmailProps {
  email: string;
  nome: string;
}

interface AlteraEmailPayloadData {
  msg: string;
  success: boolean;
}

interface AlteraNomeEmailPayload {
  data: AlteraEmailPayloadData;
}

const alteraNomeEmail = ({
  email,
  nome,
}: AlteraNomeEmailProps): Promise<AlteraNomeEmailPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.put(
    '/altera-email/',
    {
      email,
      nome,
      token,
    },
    {
      headers: {
        Authorization: token.toString(),
      },
    },
  );
};
export default alteraNomeEmail;
