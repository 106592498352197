import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';

import Loader from 'components/Loader';
import CriarCategoria from 'pages/Categorizacao/pages/Categorias/CriarCategoria';
import { PrivateRoute } from './PrivateRoute';

const Categorias = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "categorizaçao-categorias" */ 'pages/Categorizacao/pages/Categorias'
    ),
);

export const rotasCategorizacao = (HomeLayout: React.FC) => {
  return {
    path: '/categorizacao',
    element: (
      <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
        <React.Suspense fallback={<Loader />}>
          <HomeLayout />
        </React.Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Categorias />,
      },
      {
        path: 'criar-categoria/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <CriarCategoria />
          </PrivateRoute>
        ),
      },
    ],
  };
};
