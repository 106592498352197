import instance from './instance';

interface RecategorizarCategoriaProps {
  pesquisa_id: number;
}

interface CategorizaUltimasRespostas {
  pesquisa_categorizada_id: number;
}

const recategorizarUltimasRespostas = async (
  body: RecategorizarCategoriaProps,
): Promise<CategorizaUltimasRespostas> => {
  const result = await instance.post('/pesquisa/recategorizar', body);

  return result.data;
};

export default recategorizarUltimasRespostas;
