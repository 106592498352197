/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';

const getAllRespondentesQuarentena = async (params: any) => {
  const result = await instance.get('/quarentena/respondentes/', {
    params: {
      ...params,
    },
  });

  return result.data;
};

export default getAllRespondentesQuarentena;
