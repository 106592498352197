import instance from './instance';

interface Filtro {
  nome_coluna_bd: string;
  nome_personalizado: string;
  tipo: number;
}

interface RelatorioFiltroData {
  success: boolean;
  result: Filtro[];
}

interface RelatorioFiltroPayload {
  data: RelatorioFiltroData;
}

const buscaRelatorioFiltro = async (
  pesquisaId: number,
): Promise<RelatorioFiltroPayload> =>
  instance.get(`/relatorio/filtros/${pesquisaId}`);

export default buscaRelatorioFiltro;
