import { Container } from 'reactstrap';

import { ConteudoDashboard } from 'components/ConteudoDashboard';
import { CriarCategoriaProvider } from './contexts/CriarCategoriaContext';
import SugestaoCategoria from './components/SugestaoCategoria';
import FormCriarCategoria from './components/FormCriarCategoria';
import NavbarCriarCategoria from './components/NavbarCriarCategoria';

import styles from './styles.module.scss';

function CriarCategoria() {
  return (
    <CriarCategoriaProvider>
      <NavbarCriarCategoria />
      <ConteudoDashboard>
        <Container>
          <p>
            Preencha os campos a seguir para configurar a inteligência
            artificial. Com essas informações será possível que a IA interprete
            respostas e associe categorias de forma assertiva.
          </p>
          <Container
            className={`${styles.criarCategoriaMainContainer} bg-white`}
          >
            <FormCriarCategoria />
            <div className={styles.divider} />
            <SugestaoCategoria />
          </Container>
        </Container>
      </ConteudoDashboard>
    </CriarCategoriaProvider>
  );
}

export default CriarCategoria;
