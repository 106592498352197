import React from 'react';

import { FormProvider } from 'contexts/FormContext';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';

import { PrivateRoute } from './PrivateRoute';

const UtilizacaoFranquia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UtilizacaoFranquia" */ 'pages/Contrato/UtilizacaoFranquia'
    ),
);

const CamposPersonalizados = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CamposPersonalizados" */ 'pages/CamposPersonalizados/index'
    ),
);

const DadosIntegracao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DadosIntegracao" */ 'pages/DadosIntegracao/index'
    ),
);

const ConfiguracoesPadrao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracoesPadrao" */ 'pages/ConfiguracoesPadrao/index'
    ),
);

const LayoutQuestionario = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracoesPadrao/Questionario" */ 'pages/ConfiguracoesPadrao/Questionario'
    ),
);

const LayoutConvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracoesPadrao/Convite" */ 'pages/ConfiguracoesPadrao/Convite/index'
    ),
);

const LayoutWidget = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracoesPadrao/Widget" */ 'pages/ConfiguracoesPadrao/Widget/index'
    ),
);

const LayoutTrativa = React.lazy(
  () =>
    /* webpackChunkName: "ConfiguracoesPadrao/Tratativa" */ import(
      'pages/ConfiguracoesPadrao/Tratativa'
    ),
);

const GerenciamentoConta = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GerenciamentoConta" */ 'pages/GerenciamentoConta/index'
    ),
);

export const rotasOrganizacao = (HomeLayout: React.FC) => {
  return {
    path: '/organizacao',
    element: (
      <PrivateRoute>
        <HomeLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'utilizacao/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <UtilizacaoFranquia />
          </PrivateRoute>
        ),
      },
      {
        path: 'campos-personalizados/',
        element: <CamposPersonalizados />,
      },
      {
        path: 'integracao/',
        element: <DadosIntegracao />,
      },
      {
        path: 'configuracao-padrao/',
        element: <ConfiguracoesPadrao />,
      },
      {
        path: 'personalizar/questionario/',
        element: (
          <FormProvider>
            <LayoutQuestionario />
          </FormProvider>
        ),
      },
      {
        path: 'personalizar/convite/',
        element: (
          <FormProvider>
            <LayoutConvite />
          </FormProvider>
        ),
      },
      {
        path: 'personalizar/widget/',
        element: (
          <FormProvider>
            <LayoutWidget />
          </FormProvider>
        ),
      },
      {
        path: 'personalizar/tratativa/',
        element: (
          <FormProvider>
            <LayoutTrativa />
          </FormProvider>
        ),
      },
      {
        path: 'conta/gerenciamento/',
        element: <GerenciamentoConta />,
      },
    ],
  };
};
