import instance from './instance';

interface LimiteRespostasPayload {
  data: { id: number };
}

const updateLimiteRespostaByPesquisaId = (body: {
  pesquisa_id: number;
  ativo?: boolean;
  limite?: number;
}): Promise<LimiteRespostasPayload> =>
  instance.put('/link/limite-respostas/atualizar', body);

export default updateLimiteRespostaByPesquisaId;
