import React from 'react';
import {
  Button as ButtonDefault,
  ButtonProps as ButtonPropsDefault,
} from 'reactstrap';

import styles from './styles.module.scss';

export type ButtonColorType =
  | 'default'
  | 'primary'
  | 'primary-dark'
  | 'primary-light'
  | 'secondary'
  | 'secondary-light'
  | 'background'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'complete'
  | 'waiting'
  | 'paused'
  | 'outline-primary'
  | 'link'
  | 'danger';

export interface ButtonProps extends ButtonPropsDefault {
  children: React.ReactNode;
  color?: ButtonColorType;
  responsive?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  color = 'primary',
  tag = 'button',
  type = 'button',
  responsive = true,
  ...rest
}) => {
  return (
    <ButtonDefault
      data-testid="button"
      className={`${styles.container} ${
        responsive ? styles.buttonResponsive : ''
      } ${className}`}
      color={color}
      tag={tag}
      type={type}
      {...rest}
    >
      {children}
    </ButtonDefault>
  );
};

export default Button;
