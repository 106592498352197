import { useRef } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';

import Modal, { ModalRef } from 'components/Modal';
import Button from 'components/Button';
import { CategoriaType } from '..';
import { useCriarCategorias } from '../../../contexts/CriarCategoriaContext';

import styles from './styles.module.scss';

interface CategoriaItemProps {
  categoria: CategoriaType;
  substituirDados: boolean;
}

const ANALYTICS_IDS: Record<string, string> = {
  Atendimento: 'cx-ia-preencher-atendimento',
  Preço: 'cx-ia-preencher-preco',
  Qualidade: 'cx-ia-preencher-qualidade',
  'Pós-venda': 'cx-ia-preencher-posvenda',
  Entrega: 'cx-ia-preencher-entrega',
  Personalização: 'cx-ia-preencher-personalizacao',
  Usabilidade: 'cx-ia-preencher-usabilidade',
  Comunicação: 'cx-ia-preencher-comunicacao',
  Inovação: 'cx-ia-preencher-inovacao',
};

export const CategoriaItem = ({
  categoria,
  substituirDados,
}: CategoriaItemProps) => {
  const modalRef = useRef<ModalRef>(null);

  const { setCategory } = useCriarCategorias();

  const toggleModal = () => {
    modalRef.current?.toggleModal && modalRef.current.toggleModal();
  };

  const handleSetCategory = () => {
    if (!substituirDados) {
      setCategory(categoria);
    } else {
      toggleModal();
    }
  };

  const handleConfirmSetCategory = () => {
    setCategory(categoria);
    toggleModal();
  };

  const analytics_tracker = ANALYTICS_IDS[categoria.nome] || '';

  return (
    <>
      <div className={styles.container}>
        <span>{categoria.nome}</span>

        <button
          className={analytics_tracker}
          type="button"
          onClick={handleSetCategory}
        >
          Preencher
        </button>
      </div>

      <Modal
        ref={modalRef}
        className={styles.modal}
        title="Substituir conteúdo"
        centered
      >
        <ModalBody>
          Ao prosseguir, o conteúdo inserido anteriormente será substituído.
          Deseja continuar?
        </ModalBody>

        <ModalFooter className={styles.modalFooter}>
          <Button outline onClick={toggleModal}>
            Cancelar
          </Button>

          <Button onClick={handleConfirmSetCategory}>
            Substituir conteúdo
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
