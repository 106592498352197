import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';

import Spinner from 'components/Spinner';
import Button, { ButtonProps } from '..';

export interface ButtonLoadingProps extends ButtonProps {
  children: React.ReactNode;
}

export interface ButtonLoadingRef {
  toggleLoading(): void;
  setLoading(isLoading: boolean): void;
}

const ButtonLoading: React.ForwardRefRenderFunction<
  ButtonLoadingRef,
  ButtonLoadingProps
> = ({ children, disabled, ...rest }, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = useCallback(() => {
    setIsLoading((state) => !state);
  }, []);

  const setLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);

  useImperativeHandle(ref, () => ({
    toggleLoading,
    setLoading,
  }));

  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {isLoading ? <Spinner /> : <>{children}</>}
    </Button>
  );
};

export default forwardRef(ButtonLoading);
