/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';
import jwt, { JwtPayload } from 'jsonwebtoken';

import LocalStorageEnum from 'enums/LocalStorageEnum';
import {
  Listas,
  Pesquisa,
  ConfiguracoesEmailPesquisa,
  PesquisaApiObj,
  Pergunta,
  UserData,
} from '../types';
import { Widget } from '../../entities/pesquisa/widget';

interface PesquisaObj {
  contato: {
    error?: string;
    items: Listas[];
    loading: boolean;
  };
  form: string | Record<string, undefined>;
  pesquisa: {
    error?: string;
    items: Pesquisa[];
    loading: boolean;
    result?: PesquisaApiObj;
  };
}

interface PesquisaStore {
  error?: string;
  items: Pesquisa[];
  loading: boolean;
  result?: any;
}

/**
 * `usePesquisa` te retornará o objeto de pesquisa. Caso o parametro não seja passado, será retornado a pesquisa atual
 *
 */
const usePesquisa = (id = 0): Pesquisa => {
  const { pathname } = useLocation();
  const { pesquisaId } = useParams();
  let idPesquisa = id || pesquisaId;

  if (!idPesquisa) {
    const pesquisaIdRegex = new RegExp(/pesquisa\/\d{1,}\//, 'ig');
    if (pesquisaIdRegex.test(pathname)) {
      const [pesquisaPath]: string[] = pathname.match(pesquisaIdRegex) || [];
      idPesquisa = parseInt(pesquisaPath.split('/')[1], 10);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pesquisaStore = useSelector((store: any): Pesquisa => {
    const pesquisaItems = store.pesquisa.items;

    const pesquisaAtual = pesquisaItems.find(
      (item: Pesquisa) => item.id === Number(idPesquisa),
    );
    return pesquisaAtual;
  });

  return pesquisaStore;
};

/**
 * `usePesquisas` te retornará o array de pesquisas.
 */
const usePesquisas = (): Pesquisa[] => {
  const pesquisaStore = useSelector((store: PesquisaObj) => {
    const pesquisaItems = store.pesquisa.items;

    return pesquisaItems;
  });
  return pesquisaStore;
};

const usePesquisaObj = (): PesquisaStore => {
  const pesquisaStore = useSelector((store: PesquisaObj) => {
    const pesquisaItems = store.pesquisa;

    return pesquisaItems;
  });
  return pesquisaStore;
};

/**
 * `useConfiguracaoEmail` retornará o objeto referente as configurações de email do envio corrente.
 */
const useConfiguracaoEmail = (): ConfiguracoesEmailPesquisa | undefined => {
  const pesquisa = usePesquisa();
  const { envioId } = useParams();
  if (pesquisa?.configuracoes_email) {
    return (
      pesquisa.configuracoes_email.find(
        (item: ConfiguracoesEmailPesquisa) => item.envio_id === Number(envioId),
      ) || pesquisa.configuracoes_email[0]
    );
  }
  return undefined;
};

/**
 * `useConfiguracaoWidget` retornará o objeto referente as configurações do widget.
 */
const useConfiguracaoWidget = (): Widget | undefined => {
  const pesquisa = usePesquisa();
  const { pesquisaId } = useParams();
  if (pesquisa?.widget) {
    return (
      pesquisa.widget.find(
        (item: Widget) => item.pesquisa_id === Number(pesquisaId),
      ) || pesquisa.widget[0]
    );
  }
  return undefined;
};

const usePerguntas = (): Pergunta[] | undefined => {
  const pesquisa = usePesquisa();
  if (pesquisa) return pesquisa.perguntas;
  return undefined;
};

const useUserData = (): UserData | Record<string, undefined> => {
  const [token] = useState(() => {
    const tokenStorage = localStorage.getItem(LocalStorageEnum.TOKEN);
    const payloadStorage = localStorage.getItem(LocalStorageEnum.PAYLOAD);
    let userData = {};

    if (tokenStorage) {
      const jsonPayload = jwt.decode(
        tokenStorage.replace(/bearer\s/gi, ''),
      ) as JwtPayload;
      userData = { ...userData, ...jsonPayload };
    }

    if (payloadStorage) {
      const payload = JSON.parse(payloadStorage);
      userData = { ...userData, ...payload };
    }

    return userData;
  });
  return token;
};

const useResult = (): PesquisaApiObj | undefined => {
  const resultPesquisa = useSelector((store: PesquisaObj) => {
    const { result } = store.pesquisa;

    return result;
  });
  return resultPesquisa;
};

export {
  usePesquisa,
  useConfiguracaoEmail,
  useConfiguracaoWidget,
  usePesquisas,
  usePesquisaObj,
  usePerguntas,
  useUserData,
  useResult,
};
