import instance from './instance';
import { QrcodeConfiguracao } from '../../entities/pesquisa/qrcode_configuracao';

interface GetConfiguracaoQrcodeProps extends QrcodeConfiguracao {
  link: string;
}

const getConfiguracaoQrcode = async (
  pesquisaId: number | string,
): Promise<GetConfiguracaoQrcodeProps> => {
  const { data } = await instance.get(`/qrcode/configuracao/${pesquisaId}`);

  return data.data;
};

export default getConfiguracaoQrcode;
