import instance from './instance';
import { IPesquisa } from './types';

interface AlterarPesquisasData {
  msg: string;
  success: boolean;
  pesquisa: IPesquisa[];
}

interface AlterarPesquisasPayload {
  data: AlterarPesquisasData;
}

const alterar = async (body: {
  id: number;
  nome: string;
  status_id: number;
  tipo_questionario_id?: number;
  data_disparo?: Date;
}): Promise<AlterarPesquisasPayload> => instance.put('/alterar', body);

export default alterar;
