import instance from './instance';
import { Lista } from '../../entities/contato/lista';

interface BuscaListaPayload {
  data: Lista[];
}

const buscaListaId = (
  id?: string | undefined | number,
): Promise<BuscaListaPayload> => instance.get(`/lista/${id}`);

export default buscaListaId;
