import { useRef } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';
import toastr from 'toastr';

import { criarCategoria } from 'services/categorizacao';
import ButtonLoading, {
  ButtonLoadingRef,
} from 'components/Button/ButtonLoading';
import { useCriarCategorias } from '../../contexts/CriarCategoriaContext';

import styles from './styles.module.scss';

function NavbarCriarCategoria() {
  const navigate = useNavigate();
  const { nome, exemplo, descricao } = useCriarCategorias();
  const submitButtonRef = useRef<ButtonLoadingRef>(null);

  const allValuesFilled = [nome, exemplo, descricao].every(
    (val) => val.length > 0,
  );

  async function handleCriarCategoria() {
    if (allValuesFilled) {
      submitButtonRef.current?.setLoading(true);

      try {
        await criarCategoria({
          nome,
          exemplo,
          descricao,
        });

        toastr.success('Categoria criada com sucesso!');
        navigate('/categorizacao', { state: { aba: 0 } });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toastr.error(
          err.response.data.msg ||
            'Erro ao criar a categoria, tente novamente!',
        );

        submitButtonRef.current?.setLoading(false);
      }
    }
  }

  return (
    <Navbar className="border-bottom" color="white" light expand="md">
      <FaArrowLeft
        className="text-primary btn-voltar pointer"
        onClick={() => navigate(-1)}
      />
      <NavbarBrand className="ml-3 text-primary">
        <span className="mr-3 header-text title-bar-pesquisa-nome">
          Criar categoria
        </span>
      </NavbarBrand>
      <Nav className="ml-auto" navbar>
        <ButtonLoading
          ref={submitButtonRef}
          color="secondary"
          onClick={handleCriarCategoria}
          disabled={!allValuesFilled}
          className={`${styles.buttonCriarCategoria} cx-ia-btn-criar-categoria`}
        >
          Criar Categoria
        </ButtonLoading>
      </Nav>
    </Navbar>
  );
}

export default NavbarCriarCategoria;
