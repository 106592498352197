import instance from './instance';

interface BuscaPesquisaRespostaPerfilProps {
  pesquisaId: number;
  colunaPerfilResposta: number;
  valorDigitado: number;
}

const buscaPesquisaRespostaPerfil = ({
  pesquisaId,
  colunaPerfilResposta,
  valorDigitado,
}: BuscaPesquisaRespostaPerfilProps) =>
  instance.get(
    `/relatorio/filtro/perfil/${pesquisaId}/${colunaPerfilResposta}/${valorDigitado}`,
  );

export default buscaPesquisaRespostaPerfil;
