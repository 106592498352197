import instance from './instance';

interface IRemoverRespondenteQuarentena {
  success: boolean;
  id: { id: number };
}

const removerRespondenteQuarentena = async (
  quarentenaRespondenteId?: string | undefined | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<IRemoverRespondenteQuarentena> => {
  const result = await instance.post(
    `/quarentena/respondente/remover/${quarentenaRespondenteId}`,
  );

  return result.data;
};
export default removerRespondenteQuarentena;
