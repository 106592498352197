/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, useCallback } from 'react';

interface FormContextData {
  submit: (formName: string, fn?: any) => any;
  getFormValues: (formName: string) => any;
  removeForm: (formName: string) => void;
  getFormFieldValue: (formName: string, field: string) => any;
  initForm: (formName: string, values: any) => void;
  setFormValue: (formName: string, field: string, value: any) => void;
  addError: (formName: string, erros: string | Record<string, string>) => void;
  handleError: (formName: string, field: string) => void;
  error: Record<string, any>;
}

const FormContext = createContext<FormContextData>({} as FormContextData);

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [form, setForm] = useState<any>({});
  const [error, setError] = useState<any>({});

  const submit = useCallback(
    (formName: string, fn?: any) => {
      const { submit: submitFn, ...formValues } = form[formName];
      if (submitFn && typeof submitFn === 'function') {
        return submitFn(formValues);
      }
      return fn(formValues);
    },
    [form],
  );

  const getFormValues = useCallback(
    (formName: string) => {
      return form?.[formName];
    },
    [form],
  );

  const removeForm = useCallback((formName: string) => {
    setForm((state: any) => {
      const newForm = { ...state };
      delete newForm[formName];
      return newForm;
    });
  }, []);

  const getFormFieldValue = useCallback(
    (formName: string, field: string) => {
      return form?.[formName]?.[field];
    },
    [form],
  );

  const initForm = useCallback((formName: string, values: any) => {
    setForm((state: any) => ({
      ...state,
      [formName]: { ...values },
    }));
  }, []);

  const setFormValue = useCallback(
    (formName: string, field: string, value: any) => {
      setForm((state: any) => {
        const newFieldValue = { [field]: value };
        const newFormValue = { ...state[formName], ...newFieldValue };

        return { ...state, [formName]: newFormValue };
      });
    },
    [],
  );

  const addError = useCallback(
    (formName: string, erros: string | Record<string, string>) => {
      setError((state: any) => ({ ...state, [formName]: erros }));
    },
    [],
  );

  const handleError = useCallback(
    (formName: string, field: string) => {
      if (error?.[formName]?.[field]) {
        const newFormError = { ...error };
        delete newFormError?.[formName]?.[field];

        setError(newFormError);
      }
    },
    [error],
  );

  return (
    <FormContext.Provider
      value={{
        submit,
        getFormValues,
        removeForm,
        getFormFieldValue,
        initForm,
        addError,
        handleError,
        setFormValue,
        error,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useForm(): FormContextData {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useForm must be used withn an FormProvider');
  }

  return context;
}
