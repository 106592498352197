import instance from './instance';

interface CategoriasType {
  id: number;
  nome: string;
}

interface RespostasPerguntaAberta {
  nome: string;
  qtd_resposta: string;
  porcentagem: number;
  categoria_restrita: boolean;
}

const getResultadoPerguntaAbertaComCategoria = async (body: {
  categorias: CategoriasType[];
  respostas_ids: number[];
  pergunta_id: number;
}): Promise<RespostasPerguntaAberta[]> => {
  const result = await instance.post('/resultado/aberta/categoria', body);

  return result.data;
};

export default getResultadoPerguntaAbertaComCategoria;
