import { Categoria } from 'entities/organizacao/categoria';
import instance from './instance';

type FiltrosType = {
  sorting_categoria?: {
    type: string;
    id: string;
    desc: boolean;
  };
  sorting_pesquisa?: {
    type: string;
    id: string;
    desc: boolean;
  };
  status?: {
    type: string;
    id: number;
  };
  nome_categoria?: { type: string; nome: string };
  nome_pesquisa?: { type: string; nome: string };
};

interface GetCategoriasProps {
  filtros: FiltrosType;
  paginaTratativa: number;
}

interface GetCategoriasResult {
  categorias: Categoria[];
  qtd_paginas: number;
  current_page: number;
}

const getCategorias = async ({
  filtros,
  paginaTratativa,
}: GetCategoriasProps): Promise<GetCategoriasResult> => {
  const result = await instance.post('/categorias', {
    filtros,
    paginaTratativa,
  });

  return result.data;
};

export default getCategorias;
