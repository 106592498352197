enum TipoPerguntaEnum {
  RESPOSTA_UNICA = 1,
  ABERTA = 2,
  NPS = 3,
  ESCALA_AVALIACAO = 4,
  BATERIA_ATRIBUTOS = 5,
  TEXTO_DESCRITIVO = 6,
  RESPOSTA_MULTIPLA = 7,
  SENTIMENTOS = 8,
  LIKE_DISLIKE = 9,
  BATERIA_ATRIBUTOS_LIKE_DISLIKE = 10,
  CES = 11,
  ESCALA_AVALIACAO_HOTMART = 12,
}

export default TipoPerguntaEnum;
