/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';
import { Pesquisa_idioma } from '../../entities/pesquisa/pesquisa_idioma';
import { Pesquisa_idioma_convite } from '../../entities/pesquisa/pesquisa_idioma_convite';
import { Envio } from './types';

interface PerguntaIdioma {
  id: number;
  metadados: any;
  pergunta_id: number;
  pesquisa_idioma_id: number;
  titulo: string;
}

interface Idioma extends Pesquisa_idioma {
  convite: Pesquisa_idioma_convite[];
  idioma: string;
  pergunta_idioma: PerguntaIdioma[];
  status: string;
}

interface GetPesquisasResult {
  envio: Envio;
  idiomasAtualizados?: Idioma[];
  pesquisa_id: number;
}

interface GetPesquisasData {
  success: boolean;
  result: GetPesquisasResult;
}

interface GetPesquisasPayload {
  data: GetPesquisasData;
}

const inserirEnvio = (body: {
  pesquisa_id: number;
  nome: string;
}): Promise<GetPesquisasPayload> => instance.post('/envio/inserir', body);

export default inserirEnvio;
