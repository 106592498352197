import instance from './instance';
import { IPaginaInicialTotem } from '../../entities/pesquisa/pagina_inicial_totem';

type AlterarPaginaInicialTotem = Omit<IPaginaInicialTotem, 'id'>;

const updatePaginaInicialTotem = async (
  body: AlterarPaginaInicialTotem,
): Promise<IPaginaInicialTotem> =>
  instance.put('/totem/pagina-inicial/atualizar', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export default updatePaginaInicialTotem;
