import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';
import { Usuario } from '../../entities/auth/usuario';

interface UserDataProps extends Usuario {
  nome_organizacao: string;
}

interface UserDataResult {
  result: UserDataProps[];
}

interface UserDataPayload {
  data: UserDataResult;
}

const getUserDataByAuthId = (): Promise<UserDataPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.get('/usuario-organizacoes/', {
    headers: {
      Authorization: token.toString(),
    },
  });
};
export default getUserDataByAuthId;
