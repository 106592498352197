import instance from './instance';

const agendaEnvioRelatorio = (body: {
  pesquisa_id: number;
  filtros: {
    coluna: string;
    nome: string;
    tipo: number;
    valor: {
      selected: string;
      text: string;
    };
  };
  endDateRange: number;
  startDateRange: number;
}) => instance.post('/agendar', body);

export default agendaEnvioRelatorio;
