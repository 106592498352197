import instance from './instance';

interface RecuperarSenhaData {
  msg: string;
  erro: boolean;
}

interface RecuperarSenhaPayload {
  data: RecuperarSenhaData;
}

const recuperarSenha = (login: string): Promise<RecuperarSenhaPayload> =>
  instance.post('/recuperar-senha/', {
    login,
  });

export default recuperarSenha;
