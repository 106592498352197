import instance from './instance';

interface TempoOciosidadePayload {
  success: boolean;
  data: number[];
}

const insertTempoOciosidadeByPesquisaId = (body: {
  pesquisa_id: number;
  tempo?: number;
}): Promise<TempoOciosidadePayload> =>
  instance.post('/link/tempo-ociosidade/inserir', body);

export default insertTempoOciosidadeByPesquisaId;
