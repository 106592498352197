import instance from './instance';

export interface IGetDestinatariosEmailTratativa {
  nome_respondente: string;
  email_respondente: string;
}

export interface IConfigEmail {
  organizacao_id: number;
  nome_remetente: string;
  responder_a: string;
  cco?: string;
  assunto: string;
  logomarca: string;
  cor_cabecalho: string;
  conteudo_email: string;
  cor_rodape: string;
}

export interface IGetDestinatariosEmailTratativaWithConfigEmail {
  configEmail?: IConfigEmail;
  destinatarios: IGetDestinatariosEmailTratativa[];
  nome_resolvente: string;
}

const getDestinatariosEmailResponderTratativa = async (
  tratativas: number[],
): Promise<IGetDestinatariosEmailTratativaWithConfigEmail> => {
  const { data } = await instance.get(
    `/close-loop/responder-tratativa/destinatarios/${tratativas}`,
  );

  return data;
};

export default getDestinatariosEmailResponderTratativa;
