import TipoListaContatoErroEnum from 'enums/TipoListaContatoErroEnum';

import instance from './instance';

export type Erros = {
  ordemColuna: number;
  tipoErro: TipoListaContatoErroEnum;
  campo: string;
};

interface ValidaPrimeirosContatosPayload {
  data: Erros[];
}

const ValidaPrimeirosContatosLista = (
  listaId: number,
): Promise<ValidaPrimeirosContatosPayload> =>
  instance.get(`/lista/contato/validar/${listaId}`);

export default ValidaPrimeirosContatosLista;
