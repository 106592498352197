import instance from './instance';

interface ResultadoEscalaData {
  media: string;
  name: string;
  quantidade_respostas: string;
}

interface ResultadoEscala {
  data: ResultadoEscalaData[];
}

const buscaResultadoEscala = (body: {
  pesquisa_id: number;
  pergunta_id: number;
  filtros?: number[];
  periodo: string;
  data: { startDateRange: Date; endDateRange: Date };
}): Promise<ResultadoEscala> => instance.post('/resultado/escala', body);

export default buscaResultadoEscala;
