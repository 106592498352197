import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface RestricaoOrganizacaoData {
  data?: string;
  success: boolean;
}

interface RestricaoOrganizacaoPayload {
  data: RestricaoOrganizacaoData;
}

const getRestricaoOrganizacao = (): Promise<RestricaoOrganizacaoPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.get('/restricao-organizacao/', {
    headers: {
      Authorization: token.toString(),
    },
  });
};
export default getRestricaoOrganizacao;
