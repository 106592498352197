import instance from './instance';

interface IProcessamentoData {
  result: boolean;
  mensage: string;
}

interface IProcessamento {
  data: IProcessamentoData;
}

const verificaProcessamentoExportacao = (
  pesquisaId: number,
): Promise<IProcessamento> => instance.get(`/verificar/${pesquisaId}`);

export default verificaProcessamentoExportacao;
