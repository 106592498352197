import instance from './instance';

interface InserirListaData {
  success: boolean;
}

interface InserirListaPayload {
  data: InserirListaData;
}

const inserirLista = (body: {
  nome: number;
  metodo_importacao_id: number;
  tipo_lista_id: number;
}): Promise<InserirListaPayload> => instance.post('/lista/inserir', body);

export default inserirLista;
