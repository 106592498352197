import instance from './instance';
import { Envio_lista } from '../../entities/pesquisa/envio_lista';

interface ListaEnvioData {
  success: boolean;
  result: Envio_lista[];
}

interface ListaEnvioPayload {
  data: ListaEnvioData;
}

const buscaListasEnvio = (envio_id: number): Promise<ListaEnvioPayload> =>
  instance.get(`/envio/listas/${envio_id}`);

export default buscaListasEnvio;
