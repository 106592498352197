/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';
import { Pesquisa_idioma } from '../../entities/pesquisa/pesquisa_idioma';
import { Pesquisa_idioma_convite } from '../../entities/pesquisa/pesquisa_idioma_convite';
import { Envio, EstatisticaEmailEnvio } from './types';

interface PerguntaIdioma {
  id: number;
  metadados: any;
  pergunta_id: number;
  pesquisa_idioma_id: number;
  titulo: string;
}

interface Idioma extends Pesquisa_idioma {
  convite?: Pesquisa_idioma_convite[];
  idioma: string;
  pergunta_idioma: PerguntaIdioma[];
  status: string;
}

interface EnvioPesquisa extends Envio {
  estatistica_email: EstatisticaEmailEnvio[];
  idiomasAtualizados?: Idioma[];
  status: string;
}

interface LembreteData {
  success: boolean;
  result: EnvioPesquisa;
}

interface LembretePayload {
  data: LembreteData;
}

const configurarLembrete = async (body: {
  id: number;
  quantidade_lembrete?: number;
  intervalo_lembrete?: number;
}): Promise<LembretePayload> => {
  const result = await instance.put('/envio/lembrete', body);

  return result;
};
export default configurarLembrete;
