import React from 'react';

import Loader from 'components/Loader';
import { Outlet } from 'react-router-dom';

const Responder = React.lazy(
  () => import(/* webpackChunkName: "Responder" */ 'pages/Responder'),
);

export const rotasResponder = {
  path: '/responder-antigo',
  element: (
    <React.Suspense fallback={<Loader />}>
      <Outlet />
    </React.Suspense>
  ),
  children: [
    {
      path: ':token/:resposta/:perguntaId',
      element: <Responder />,
    },
    {
      path: ':token/:resposta',
      element: <Responder />,
    },
    {
      path: ':token',
      element: <Responder />,
    },
  ],
};
