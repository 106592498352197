import React from 'react';
import { Spinner } from 'reactstrap';

const Loader: React.FC = () => {
  return (
    <>
      <div className="loading h-100 w-100" style={{ top: 0, left: 0 }} />
      <div
        className="d-flex justify-content-center align-content-center spinner"
        style={{ top: 0, left: 0 }}
      >
        <Spinner color="primary" className="m-auto" />
      </div>
    </>
  );
};

export default Loader;
