import React from 'react';

type ConteudoDashboardProps = {
  children: React.ReactNode;
  className?: string;
};

export const ConteudoDashboard: React.FC<ConteudoDashboardProps> = ({
  children,
  className,
}) => (
  <div
    id="containerDashboad"
    className={`h-100  flex-grow-1 overflow-auto conteudo-pagina ${className}`}
  >
    {children}
  </div>
);
