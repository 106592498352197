import React from 'react';

import Loader from 'components/Loader';
import { Outlet } from 'react-router-dom';

const ResponderV3 = React.lazy(
  () => import(/* webpackChunkName: "ResponderV3" */ 'pages/ResponderV3'),
);

export const rotasResponderV3Link = {
  path: '/responderv3/l',
  element: (
    <React.Suspense fallback={<Loader />}>
      <Outlet />
    </React.Suspense>
  ),
  children: [
    {
      path: ':link_token',
      element: <ResponderV3 />,
    },
  ],
};
