export default class AcoesContato {
  constructor(contatos) {
    this.contatos = contatos.length > 0 ? contatos : [];
  }

  setContatos(contatos) {
    this.contatos = contatos.sort((prev, next) => prev.id - next.id);
  }

  addContatos(novoContato) {
    if (this.contatos.length > 0) {
      const { contatos } = this;
      contatos.push(novoContato);
      this.setContatos(contatos);

      return this.contatos;
    }

    const contatos = [novoContato];

    this.setContatos(contatos);

    return this.contatos;
  }

  atualizaContatos(novoContato) {
    const contatos = this.contatos.map((contatosItem) => {
      if (contatosItem.id === novoContato.id) {
        return {
          ...contatosItem,
          ...novoContato,
        };
      }
      return contatosItem;
    });

    this.setContatos(contatos);

    return this.contatos;
  }

  addOuAtualizaContatos(novoContato) {
    const contatoExiste = this.contatos.find(
      (contatosItem) => contatosItem.id === novoContato.id,
    );

    if (contatoExiste) {
      return this.atualizaContatos(novoContato);
    }
    return this.addContatos(novoContato);
  }

  incrementaContatos(contato) {
    const contatos = this.contatos.map((contatosItem) => {
      if (contatosItem.id === contato.id) {
        return {
          ...contatosItem,
          ...contato,
          total_sucesso: (contatosItem.total_sucesso || 0) + 1,
        };
      }
      return contatosItem;
    });

    this.setContatos(contatos);

    return this.contatos;
  }

  atualizaTotalContatosRetomar(contato) {
    contato.result.forEach((item) => {
      const contatos = this.contatos.map((contatosItem) => {
        if (contatosItem.id === item.lista_id) {
          return {
            ...contatosItem,
            total_sucesso: (contatosItem.total_sucesso || 0) + item.total_contatos,
          };
        }
        return contatosItem;
      });

      this.setContatos(contatos);
    });

    return this.contatos;
  }

  atualizaTotalContatosExcluir(contato) {
    contato.result.forEach((item) => {
      const contatos = this.contatos.map((contatosItem) => {
        if (contatosItem.id === item.lista_id) {
          return {
            ...contatosItem,
            total_sucesso: (contatosItem.total_sucesso || 0) - item.total_contatos,
          };
        }
        return contatosItem;
      });

      this.setContatos(contatos);
    });

    return this.contatos;
  }

  removeListaContato(listaId) {
    const contatos = this.contatos.filter(
      (contatoItem) => contatoItem.id !== listaId,
    );

    this.setContatos(contatos);

    return this.contatos;
  }

  listaModificada({ id, arquivoInserido }) {
    const contatos = this.contatos.map((contatosItem) => {
      if (contatosItem.id === id) {
        if (!arquivoInserido) {
          return {
            ...contatosItem,
            arquivoInserido: false,
            arquivo: null,
          };
        }
        return {
          ...contatosItem,
          arquivoInserido,
        };
      }
      return contatosItem;
    });

    this.setContatos(contatos);


    return this.contatos
  }
}
