import instance from './instance';

export type GetConfiguraçaoSmsType = {
  sms_configuracao_envio_api_id?: number;
  api_intervalo_is_hora?: number;
  api_intervalo?: number;
  sms_configuracao_lembrete_id?: number;
  sms_configuracao_mensagem_id?: number;
  sms_envio_lista_id?: number;
  total_sucesso?: number;
  quantidade_lembrete?: number;
  intervalo_lembrete?: number;
};

const getConfiguraçaoSms = async (
  pesquisa_id: number,
  sms_envio_id?: string,
): Promise<GetConfiguraçaoSmsType[]> => {
  const url = sms_envio_id
    ? `/configuracao/${pesquisa_id}/${sms_envio_id}`
    : `/configuracao/${pesquisa_id}/null`;

  const response = await instance.get(url);

  return response.data.result;
};

export default getConfiguraçaoSms;
