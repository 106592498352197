import instance from './instance';
import { MetadadoType } from './types';

interface ListaManualData {
  lista?: {
    id: string;
    arquivo: string;
    metadados?: MetadadoType;
  };
  success: boolean;
}

interface ListaManualPayload {
  data: ListaManualData;
}

const adicionarListaManual = (body: {
  lista_id: number;
  contato: string;
}): Promise<ListaManualPayload> => instance.post('/lista/manual', body);

export default adicionarListaManual;
