import instance from './instance';
import { Lista } from '../../entities/contato/lista';

interface InserirListaData {
  success: boolean;
  lista?: Lista;
}

interface InserirListaPayload {
  data: InserirListaData;
}

const inserirListaArquivo = (body: {
  lista_id: number;
}): Promise<InserirListaPayload> =>
  instance.post('/lista/inserir/arquivo', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export default inserirListaArquivo;
