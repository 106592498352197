import instance from './instance';
import { PesquisaObject } from './types';

interface GetPesquisasData {
  id: string;
  msg: string;
  result: PesquisaObject;
  success: boolean;
}

interface GetPesquisasPayload {
  data: GetPesquisasData;
}

interface GetPesquisaIndividualProps {
  id: number;
}

const getPesquisaIndividual = ({
  id,
}: GetPesquisaIndividualProps): Promise<GetPesquisasPayload> =>
  instance.get(`/individual/${id}`);

export default getPesquisaIndividual;
