import axios from 'axios';

import LocalStorageEnum from 'enums/LocalStorageEnum';

const baseURL = process.env.REACT_APP_BASE_URL_CX_SERVER;

const instance = axios.create({
  baseURL: `${baseURL}/pesquisa`,
});

instance.interceptors.request.use(
  (config) => {
    const novaConfig = config;
    const token = localStorage.getItem(LocalStorageEnum.TOKEN);

    if (token && novaConfig.headers) {
      novaConfig.headers.Authorization = token;
    }
    return novaConfig;
  },

  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(LocalStorageEnum.TOKEN);
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default instance;
