import { useEffect, useState } from 'react';

import { getSugestaoCategorias } from 'services/categorizacao';
import { Spinner } from 'reactstrap';
import toastr from 'toastr';
import { useCriarCategorias } from '../../contexts/CriarCategoriaContext';

import { CategoriaItem } from './CategoriaItem';

import styles from './styles.module.scss';

export type CategoriaType = {
  nome: string;
  descricao: string;
  exemplo: string;
};

function SugestaoCategoria() {
  const { nome, exemplo, descricao } = useCriarCategorias();

  const substituirDados = [nome, exemplo, descricao].some(
    (data) => data !== '',
  );

  const [categorias, setCategorias] = useState<CategoriaType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getSugestaoCategorias()
      .then((response) => {
        setCategorias(response.data);
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          'Não foi possível carregar as categorias. Recarregue a página e tente novamente.',
        );
      });
  }, []);

  return (
    <div className={`d-flex flex-column ${styles.sugestaoCategoriaContainer}`}>
      <p className="text-primary titulo-negrito m-0">Sugestões de categorias</p>
      <p className="p-0 my-2">
        Para agilizar a criação das suas categorias, você pode utilizar e editar
        uma das sugestões abaixo.
      </p>

      {loading ? (
        <div className={styles.DivLoading}>
          <Spinner color="primary" className="m-auto" />
          <span>As categorias estão sendo carregadas...</span>
        </div>
      ) : (
        categorias.map((categoria) => (
          <CategoriaItem
            key={categoria.nome}
            categoria={categoria}
            substituirDados={substituirDados}
          />
        ))
      )}
    </div>
  );
}

export default SugestaoCategoria;
