import instance from './instance';

interface ContatosEnvioData {
  success: boolean;
  result: number;
}

interface ContatosEnvioPayload {
  data: ContatosEnvioData;
}

const atualizarTotalContatosEnvio = (body: {
  envio_id: number;
  total_contatos: number;
}): Promise<ContatosEnvioPayload> =>
  instance.post('/envio/contato/alterar', body);

export default atualizarTotalContatosEnvio;
