import instance from './instance';
import { Limite_resposta } from '../../entities/pesquisa/limite_resposta';

interface LimiteRespostasPayload {
  status: number;
  data: Limite_resposta;
}

const insertLimiteRespostaByPesquisaId = (body: {
  pesquisa_id: number;
  ativo?: boolean;
  limite?: number;
  tempo?: number | string;
}): Promise<LimiteRespostasPayload> =>
  instance.post('/link/limite-respostas/inserir', body);

export default insertLimiteRespostaByPesquisaId;
