import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

const alteraPermissaoUsuario = (body: {
  user: string;
  role_id: number;
  restricao?: string;
}) => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.put('/alterar-permissao/', body, {
    headers: {
      Authorization: token.toString(),
    },
  });
};
export default alteraPermissaoUsuario;
