import React from 'react';

import { Outlet } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

const PreviewWidget = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreviewWidget" */ '../pages/Widget/PreviewWidget'
    ),
);

const VerQuestionarioWidget = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreviewWidget" */ '../pages/Widget/VerQuestionarioWidget'
    ),
);

/* export const rotasPreviewWidget = {
  path: '/preview-widget/:pesquisaId/:token',
  element: (
    <PrivateRoute>
      <PreviewWidget />
    </PrivateRoute>
  ),
}; */

export const rotasPreviewWidget = {
  path: '/preview-widget',
  element: (
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  ),
  children: [
    {
      path: ':pesquisaId/:token',
      element: <PreviewWidget />,
    },
    {
      path: 'responder/:pesquisaId/:token',
      element: <VerQuestionarioWidget />,
    },
  ],
};

/* export const rotasResponder = {
  path: '/preview-widget',
  element: (
     <PrivateRoute>
      <Outlet />
   </PrivateRoute>
  ),
  children: [
    {
      path: ':pesquisaId/:token',
      element: <PreviewWidget />,
    },
     {
      path: 'responder/:pesquisaId/:token',
      element: <VerQuestionarioWidget />,
    },

  ],
}; */
