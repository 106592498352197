import { Categoria } from 'entities/organizacao/categoria';
import instance from './instance';

type GetConfiguraçaoByIdResponseType = {
  categorias_configuradas?: Categoria[];
  categorias: number[];
  perguntas: number[];
};

export const getConfiguraçaoById = async (
  pesquisaId: number,
): Promise<GetConfiguraçaoByIdResponseType> => {
  const result = await instance.get(`/configuracao/${pesquisaId}`);

  return result.data;
};
