import instance from './instance';

interface IRestaurarRespondentes {
  success: boolean;
  id: { id: number };
}

const restaurarRespondenteLista = async (
  respondente_id: number,
  listaId: number | null,
): Promise<IRestaurarRespondentes> => {
  const result = await instance.post(
    `/respondentes/lista/restaurar/${respondente_id}`,
    { listaId },
  );

  return result.data;
};

export default restaurarRespondenteLista;
