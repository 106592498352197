import instance from './instance';

interface ResultadoCesData {
  media: string;
  name: string;
  quantidade_respostas: string;
}

interface ResultadoCes {
  data: ResultadoCesData[];
}

const buscaResultadoCes = (body: {
  pesquisa_id: number;
  pergunta_id: number;
  filtros?: number[];
  periodo: string;
  data: { startDateRange: Date | string; endDateRange: Date | string };
}): Promise<ResultadoCes> => instance.post('/resultado/ces', body);

export default buscaResultadoCes;
