export const LISTA_PESQUISAS = 'LISTA_PESQUISAS';
export const ATUALIZA_PESQUISAS = 'ATUALIZA_PESQUISAS';
export const ATUALIZA_PESQUISA_INDIVIDUAL = 'ATUALIZA_PESQUISA_INDIVIDUAL';
export const ATUALIZA_PESQUISAS_RESULT = 'ATUALIZA_PESQUISAS_RESULT';
export const INSERIR_PESQUISA = 'INSERIR_PESQUISA';
export const DUPLICAR_PESQUISA = 'DUPLICAR_PESQUISA';
export const INSERIR_ENVIO = 'INSERIR_ENVIO';
export const ALTERAR_PESQUISA = 'ALTERAR_PESQUISA';
export const INSERIR_PESQUISA_RESULT = 'INSERIR_PESQUISA_RESULT';
export const LISTA_PESQUISAS_SUCCESS = 'LISTA_PESQUISAS_SUCCESS';
export const LISTA_PESQUISAS_FAIL = 'LISTA_PESQUISAS_FAIL';
export const ORDENAR_PESQUISA = 'ORDENAR_PESQUISA';
export const INSERIR_PERGUNTA = 'INSERIR_PERGUNTA';
export const ATUALIZAR_PERGUNTA = 'ATUALIZAR_PERGUNTA';
export const ATUALIZAR_TODAS_PERGUNTAS = 'ATUALIZAR_TODAS_PERGUNTAS';
export const PESQUISA_SUCCESS = 'PESQUISA_SUCCESS';
export const PESQUISA_ERROR = 'PESQUISA_ERROR';
export const CONTATO_SUCCESS = 'CONTATO_SUCCESS';
export const CONTATO_ERROR = 'CONTATO_ERROR';
export const INSERIR_PAGINA = 'INSERIR_PAGINA';
export const ATUALIZA_PAGINAS = 'ATUALIZA_PAGINAS';
export const ALTERAR_PERGUNTA = 'ALTERAR_PERGUNTA';
export const INSERIR_CONFIGURACAO_EMAIL = 'INSERIR_CONFIGURACAO_EMAIL';
export const INSERIR_CONFIGURACAO_WIDGET = 'INSERIR_CONFIGURACAO_WIDGET';
export const ATUALIZA_CONFIGURACAO_WIDGET = 'ATUALIZA_CONFIGURACAO_WIDGET';
export const INSERIR_WIDGET_STATE = 'INSERIR_WIDGET_STATE';
export const ALTERAR_CONFIGURACAO_EMAIL = 'ALTERAR_CONFIGURACAO_EMAIL';
export const ATUALIZA_CONFIGURACAO_EMAIL = 'ATUALIZA_CONFIGURACAO_EMAIL';
export const BUSCA_LISTA = 'BUSCA_LISTA';
export const BUSCA_LISTA_RESULT = 'BUSCA_LISTA_RESULT';
export const INSERIR_LISTA = 'INSERIR_LISTA';
export const ATUALIZA_LISTA_INDIVIDUAL = 'ATUALIZA_LISTA_INDIVIDUAL';
export const INCREMENTA_LISTA_INDIVIDUAL = 'INCREMENTA_LISTA_INDIVIDUAL';
export const INSERIR_LISTA_ARQUIVO = 'INSERIR_LISTA_ARQUIVO';
export const INSERIR_LISTA_MANUAL = 'INSERIR_LISTA_MANUAL';
export const ALTERAR_LISTA = 'ALTERAR_LISTA';
export const INSERIR_LISTA_RESULT = 'INSERIR_LISTA_RESULT';
export const PROCESSAR_LISTA = 'PROCESSAR_LISTA';
export const PROCESSAR_LISTA_RESULT = 'PROCESSAR_LISTA_RESULT';
export const ALTERAR_PAGINA_AGRADECIMENTO = 'ALTERAR_PAGINA_AGRADECIMENTO';
export const ALTERAR_DATA_PAGINA_AGRADECIMENTO =
  'ALTERAR_DATA_PAGINA_AGRADECIMENTO';
export const INSERIR_LISTA_ENVIO = 'INSERIR_LISTA_ENVIO';
export const ATUALIZAR_TOTAL_CONTATOS_ENVIO = 'ATUALIZAR_TOTAL_CONTATOS_ENVIO';
export const ALTERAR_AGRADECIMENTO_RESULT = 'ALTERAR_AGRADECIMENTO_RESULT';
export const ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT =
  'ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT';
export const CRIA_QUESTIONARIO = 'CRIA_QUESTIONARIO';
export const ENVIA_EMAIL_TESTE = 'ENVIA_EMAIL_TESTE';
export const FINALIZA_EMAIL_TESTE = 'FINALIZA_EMAIL_TESTE';
export const INSERIR_PERSONALIZAR_LAYOUT = 'INSERIR_PERSONALIZAR_LAYOUT';
export const ALTERAR_PERSONALIZAR_LAYOUT = 'ALTERAR_PERSONALIZAR_LAYOUT';
export const ATUALIZA_LAYOUT_PERSONALIZADO = 'ATUALIZA_LAYOUT_PERSONALIZADO';
export const IMPORTA_QUESTIONARIO_MODELO = 'IMPORTA_QUESTIONARIO_MODELO';
export const RESULTADO_CONFIGURACAO_EMAIL = 'RESULTADO_CONFIGURACAO_EMAIL';
export const ATUALIZA_ENVIO_PESQUISA = 'ATUALIZA_ENVIO_PESQUISA';
export const ATIVA_LOADER = 'ATIVA_LOADER';
export const DESATIVA_LOADER = 'DESATIVA_LOADER';
export const ATIVA_LOADER_CONTATOS = 'ATIVA_LOADER_CONTATOS';
export const DESATIVA_LOADER_CONTATOS = 'DESATIVA_LOADER_CONTATOS';
export const ATUALIZA_LISTA_ENVIO = 'ATUALIZA_LISTA_ENVIO';
export const REMOVE_LISTA_CONTATO = 'REMOVE_LISTA_CONTATO';
export const BUSCA_PESQUISA_INDIVIDUAL = 'BUSCA_PESQUISA_INDIVIDUAL';
export const ATUALIZA_NOME_ENVIO = 'ATUALIZA_NOME_ENVIO';
export const AGENDA_ENVIO_RELATORIO = 'AGENDA_ENVIO_RELATORIO';
export const ALTERA_INTERVALO_ENVIO_API = 'ALTERA_INTERVALO_ENVIO_API';
export const ENVIO_RESULT_SUCCESS = 'ENVIO_RESULT_SUCCESS';
export const FINALIZA_PESQUISA_API = 'FINALIZA_PESQUISA_API';
export const ALTERA_PERFIL_GERENCIAMENTO = 'ALTERA_PERFIL_GERENCIAMENTO';
export const ALTERA_SENHA_USUARIO = 'ALTERA_SENHA_USUARIO';
export const INSERIR_TRADUCAO_PERGUNTA = 'INSERIR_TRADUCAO_PERGUNTA';
export const INSERIR_TRADUCAO_CONVITE = 'INSERIR_TRADUCAO_CONVITE';
export const UPDATE_IDIOMA = 'UPDATE_IDIOMA';
export const INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO =
  'INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO';
export const CLEAR_STATE = 'CLEAR_STATE';
export const INSERIR_IDIOMA = 'INSERIR_IDIOMA';
export const REMOVE_IDIOMA_PESQUISA = 'REMOVE_IDIOMA_PESQUISA';
export const ALTERAR_IDIOMA_PESQUISA = 'ALTERAR_IDIOMA_PESQUISA';
export const PERGUNTA_REMOVIDA = 'PERGUNTA_REMOVIDA';
export const REMOVER_LIMITE_RESPOSTA = 'REMOVER_LIMITE_RESPOSTA';
export const UPDATE_LIMITE_RESPOSTA = 'UPDATE_LIMITE_RESPOSTA';
export const NOVO_LIMITE_RESPOSTA = 'NOVO_LIMITE_RESPOSTA';
export const INSERT_LIMITE_RESPOSTA = 'INSERT_LIMITE_RESPOSTA';
export const UPDATE_ARRAY_LIMITE_RESPOSTA = 'UPDATE_ARRAY_LIMITE_RESPOSTA';
export const INSERT_TEMPO_OCIOSIDADE = 'INSERT_TEMPO_OCIOSIDADE';
export const UPDATE_TEMPO_REINICIA_TOTEM = 'UPDATE_TEMPO_REINICIA_TOTEM';
export const UPDATE_CONFIGURACAO_QUARENTENA = 'UPDATE_CONFIGURACAO_QUARENTENA';
export const RESULTADO_CONFIGURACAO_QUARENTENA = 'RESULTADO_CONFIGURACAO_QUARENTENA';
export const ATUALIZA_CONFIGURACAO_QUARENTENA = 'ATUALIZA_CONFIGURACAO_QUARENTENA';
export const ALTERAR_NOME_LISTA = 'ALTERAR_NOME_LISTA';
export const ATUALIZA_NOME_LISTA = 'ATUALIZA_NOME_LISTA';
export const AVANCAR_LISTA_EXISTENTE = 'AVANCAR_LISTA_EXISTENTE';
export const PRIMEIROS_CONTATOS_LISTA_VALIDADOS = 'PRIMEIROS_CONTATOS_LISTA_VALIDADOS';
export const PRIMEIROS_CONTATOS_LISTA_ERRO = 'PRIMEIROS_CONTATOS_LISTA_ERRO';
export const EXCLUIR_CONTATO = 'EXCLUIR_CONTATO';
export const RETOMAR_CONTATO = 'RETOMAR_CONTATO';
export const ARQUIVO_MODIFICADO = 'ARQUIVO_MODIFICADO';
export const LISTA_MODIFICADA = 'LISTA_MODIFICADA';
