import instance from './instance';

interface ResultadoBateriaData {
  name: string;
  qtde_respostas: string;
  v: string;
}

interface ResultadoBateria {
  data: ResultadoBateriaData[];
}

const buscaResultadoBateria = (body: {
  pesquisa_id: number;
  pergunta_id: number;
  filtros?: number[];
  periodo: string;
  data: { startDateRange: Date; endDateRange: Date };
}): Promise<ResultadoBateria> => instance.post('/resultado/bateria', body);

export default buscaResultadoBateria;
