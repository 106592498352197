import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';

interface UserDataAdminProps {
  id: number;
  login: string;
}

interface NovoUsuarioAdminData {
  msg: string;
  usuario: UserDataAdminProps;
}

interface NovoUsuarioAdminPayload {
  data: NovoUsuarioAdminData;
}

const inserirNovoUsuarioAdminSistema = async (body: {
  nome: string;
  email: string;
  senha: string;
  role_id: number;
}): Promise<NovoUsuarioAdminPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }
  return instance.post('/inserirAdminSistema', body, {
    headers: {
      Authorization: token.toString(),
    },
  });
};

export default inserirNovoUsuarioAdminSistema;
