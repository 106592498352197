import instance from './instance';

interface GetTesteIdiomaQuestionarioPayload {
  data: { success: boolean; token: string };
}

interface GetTesteIdiomaQuestionarioProps {
  pesquisaId: number;
  idioma_id: number;
}

const getTesteIdiomaQuestionario = ({
  pesquisaId,
  idioma_id,
}: GetTesteIdiomaQuestionarioProps): Promise<GetTesteIdiomaQuestionarioPayload> =>
  instance.get(`/teste/${pesquisaId}/${idioma_id}`);

export default getTesteIdiomaQuestionario;
