import instance from './instance';

interface GetPrincipaisCategoriasProps {
  pergunta_id: number;
  respostas_ids: number[];
}

export const getPrincipaisCategorias = async (
  data: GetPrincipaisCategoriasProps,
) => {
  const result = await instance.post(
    '/resultado/aberta/principais-categorias',
    data,
  );

  return result;
};
