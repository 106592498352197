import instance from './instance';

interface OrdernarData {
  success: boolean;
}

interface OrdernarPayload {
  data: OrdernarData;
}

const ordenar = (body: {
  ordem: number;
  pesquisa_id: number;
}): Promise<OrdernarPayload> => instance.put('/ordenar', body);

export default ordenar;
