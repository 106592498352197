export type SelectionState = Record<string | number, boolean>;

export interface CategorizationReducerState {
  categoriasSelecionadasIds: SelectionState;
  perguntasSelecionadasIds: SelectionState;
  steps: number;
  currentStep: number;
  stepsCompletion: boolean[];
}

export enum ActionTypes {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  CHANGE_STEP = 'CHANGE_STEP',
  SELECT_CATEGORIAS = 'SELECT_CATEGORIAS',
  SELECT_PERGUNTAS = 'SELECT_PERGUNTAS',
}

export type CategorizacaoActions =
  | { type: ActionTypes.NEXT_STEP }
  | { type: ActionTypes.PREVIOUS_STEP }
  | { type: ActionTypes.CHANGE_STEP; step: number }
  | { type: ActionTypes.SELECT_PERGUNTAS; perguntas: SelectionState }
  | { type: ActionTypes.SELECT_CATEGORIAS; categorias: SelectionState };

export function CategorizacaoReducer(
  state: CategorizationReducerState,
  action: CategorizacaoActions,
): CategorizationReducerState {
  switch (action.type) {
    case ActionTypes.PREVIOUS_STEP: {
      if (state.currentStep > 1) {
        return {
          ...state,
          currentStep: 1,
        };
      }

      return {
        ...state,
        currentStep: 0,
      };
    }

    case ActionTypes.NEXT_STEP: {
      if (state.currentStep === 0) {
        const perguntasSelecionadas = Object.keys(
          state.perguntasSelecionadasIds,
        );

        const { length } = perguntasSelecionadas;
        if (length >= 1 && length <= 2) {
          return {
            ...state,
            currentStep: 1,
            stepsCompletion: [true, false],
          };
        }
      } else if (state.currentStep === 1) {
        const categoriasSelecionadas = Object.keys(
          state.categoriasSelecionadasIds,
        );

        const { length } = categoriasSelecionadas;
        if (length >= 1 && length <= 15) {
          return {
            ...state,
            currentStep: 2,
            stepsCompletion: [true, true],
          } as CategorizationReducerState;
        }
      }

      break;
    }

    case ActionTypes.CHANGE_STEP: {
      return {
        ...state,
        currentStep: action.step,
      };
    }

    case ActionTypes.SELECT_PERGUNTAS: {
      const { perguntas: perguntasSelecionadas } = action;
      const perguntasSelecionadasIds = Object.keys(perguntasSelecionadas);
      const { length } = perguntasSelecionadasIds;

      const defaultState = {
        ...state,
        stepsCompletion: [false, false],
      };

      if (length > 2) {
        return defaultState;
      }

      if (length >= 1) {
        return {
          ...state,
          perguntasSelecionadasIds: perguntasSelecionadas,
          stepsCompletion: [true, false],
        };
      }

      return {
        ...state,
        perguntasSelecionadasIds: perguntasSelecionadas,
        stepsCompletion: [false, false],
      };
    }

    case ActionTypes.SELECT_CATEGORIAS: {
      const { categorias: categoriasSelecionadas } = action;
      const categoriasSelecionadasIds = Object.keys(categoriasSelecionadas);
      const { length } = categoriasSelecionadasIds;

      if (length >= 1 && length <= 15) {
        return {
          ...state,
          categoriasSelecionadasIds: categoriasSelecionadas,
          stepsCompletion: [true, true],
          currentStep: 2,
        };
      }

      return {
        ...state,
        categoriasSelecionadasIds: categoriasSelecionadas,
        currentStep: 1,
        stepsCompletion: [true, false],
      };
    }

    default:
      return state;
  }

  return state;
}

export function generateSelectionStateFromArray<T>(arr: T[]) {
  return arr.reduce((obj, item) => {
    const key = typeof item === 'string' ? item : String(item);
    obj[key] = true;
    return obj;
  }, {} as Record<string, boolean>);
}
