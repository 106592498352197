import axios from 'axios';

import LocalStorageEnum from 'enums/LocalStorageEnum';

const baseURL =
  process.env.NODE_ENV === 'test'
    ? ''
    : process.env.REACT_APP_BASE_URL_CX_SERVER;

const instance = axios.create({
  baseURL: `${baseURL}/categorizacao`,
});

instance.interceptors.request.use(
  (config) => {
    const novaConfig = config;
    const token = localStorage.getItem(LocalStorageEnum.TOKEN);

    if (token && novaConfig.headers) {
      novaConfig.headers.Authorization = token;
    }
    return novaConfig;
  },

  (error) => Promise.reject(error),
);

export default instance;
