import { Navbar } from 'reactstrap';

import styles from './styles.module.scss';

type StepbarProps = {
  currentStep: number;
  onPreviousStep: () => void;
};

function getStepStyle(currentStep: number, step: number) {
  if (currentStep < step) return styles.stepIsDisabled;
  if (currentStep === step) return styles.stepIsActive;

  return styles.stepDone;
}

function StepBar({ currentStep = 0, onPreviousStep }: StepbarProps) {
  function handlePreviousStep() {
    if (currentStep >= 1) onPreviousStep();
  }

  return (
    <Navbar
      light
      expand="md"
      className="bg-white border-bottom d-flex justify-content-center"
    >
      <div className={styles.stepbarMainContainer}>
        <div
          className={`pointer ${styles.stepbar__steps} ${getStepStyle(
            currentStep,
            0,
          )}`}
          onClick={handlePreviousStep}
        >
          <span>1</span>
          <p>Selecione as perguntas</p>
        </div>
        <div
          className={`${styles.stepbar__steps} ${getStepStyle(
            Math.min(1, currentStep),
            1,
          )}`}
        >
          <span>2</span>
          <p>Selecione as categorias</p>
        </div>
      </div>
    </Navbar>
  );
}

export default StepBar;
