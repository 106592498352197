/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FiltroColumn,
  FiltroNome,
  FiltroSorting,
} from 'pages/Contatos/Blocklist/types/types';
import instance from './instance';

interface OutputGetAllRespondentesBlocklist {
  respondentes: {
    identificador: string;
    nome: string;
    email: string;
    celular: number;
    data_criacao: Date;
    respondente_id: number;
    ativo: boolean;
  }[];
  qtd_paginas: number;
  data_criacao_antiga: Date | null;
}

interface InputGetAllRespondentesBlocklist {
  pagina_atual: number;
  data_criacao: {
    startDateRange: Date;
    endDateRange: Date;
  };
  filtros?: {
    sorting?: FiltroSorting;
    filtroColumn?: FiltroColumn;
    nome?: FiltroNome;
  };
}

const getAllRespondentesBlocklist = async (
  params: InputGetAllRespondentesBlocklist,
): Promise<OutputGetAllRespondentesBlocklist> => {
  const result = await instance.get('/blocklist/respondentes/', {
    params: {
      ...params,
    },
  });

  return result.data;
};

export default getAllRespondentesBlocklist;
