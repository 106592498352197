import instance from './instance';
import { IPaginaInicialTotem } from '../../entities/pesquisa/pagina_inicial_totem';

const getPaginaInicialTotemByPesquisaId = async (
  pesquisaId: number | string,
): Promise<IPaginaInicialTotem> => {
  const { data } = await instance.get(`/totem/pagina-inicial/${pesquisaId}`);

  return data.data;
};

export default getPaginaInicialTotemByPesquisaId;
