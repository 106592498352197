import instance from './instance';
import { Lista } from './types';

interface ListaInserida extends Lista {
  metodo_importacao_id: number;
  tipo_lista_id: number;
}

interface InserirListaData {
  success: boolean;
  result: ListaInserida[];
}

interface InserirListaPayload {
  data: InserirListaData;
}

const inserirListaEnvio = (body: {
  envio_id: number;
  lista_id: number;
}): Promise<InserirListaPayload> => instance.post('/envio/lista', body);

export default inserirListaEnvio;
