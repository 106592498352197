import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pesquisa, Listas, ContatoResult } from '../types';

export interface ListasContato extends Listas {
  nome_personalizado: string[];
  nome_coluna_bd: string[];
  arquivoInserido?: boolean;
}

interface PesquisaObj {
  contato: {
    error?: string;
    items: ListasContato[];
    loading: boolean;
    result?: ContatoResult;
  };
  form: string | Record<string, undefined>;
  pesquisa: {
    error?: string;
    items: Pesquisa[];
    loading: boolean;
  };
}

const useContatos = (): ListasContato[] => {
  const contatos = useSelector((store: PesquisaObj) => store.contato.items);
  const [contatosState, setContatosState] = useState(contatos);

  useEffect(() => {
    setContatosState(contatos);
  }, [contatos]);

  return contatosState;
};

const useListaContatos = (listaId: string): ListasContato | undefined => {
  const listaStore = useSelector((store: PesquisaObj) => {
    const todasListas = store.contato.items;

    const listaAtual = todasListas.find(
      (item: ListasContato) => item.id === Number(listaId),
    );
    return listaAtual;
  });

  return listaStore;
};

export { useContatos, useListaContatos };
