import instance from './instance';
import { Pergunta } from '../../entities/pergunta/pergunta';

interface InserirData {
  success: boolean;
}

interface InserirPayload {
  data: InserirData;
}

const inserir = (body: Pergunta): Promise<InserirPayload> =>
  instance.post('/inserir', body);

export default inserir;
