import { createContext, useContext, useReducer } from 'react';

type CriarCategoriaReducerState = {
  nome: string;
  descricao: string;
  exemplo: string;
};

type CriarCategoriaContextData = CriarCategoriaReducerState & {
  setFieldValue(field: CategoriaStateFields, value: string): void;
  setCategory(category: CriarCategoriaReducerState): void;
};

const CriarCategoriaContext = createContext<CriarCategoriaContextData | null>(
  null,
);

const INITIAL_STATE = {
  nome: '',
  descricao: '',
  exemplo: '',
};

type CategoriaStateFields = keyof CriarCategoriaReducerState;

type CriarCategoriaActions =
  | {
      type: 'SET_FIELD';
      field: CategoriaStateFields;
      value: string;
    }
  | {
      type: 'SET_CATEGORY';
      category: CriarCategoriaReducerState;
    };

function criarCategoriaReducer(
  state: CriarCategoriaReducerState,
  action: CriarCategoriaActions,
): CriarCategoriaReducerState {
  switch (action.type) {
    case 'SET_FIELD': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'SET_CATEGORY': {
      return {
        ...state,
        ...action.category,
      };
    }
    default:
      return state;
  }
}
type CriarCategoriaProviderProps = { children: React.ReactNode };

export const CriarCategoriaProvider: React.FC<CriarCategoriaProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(criarCategoriaReducer, INITIAL_STATE);

  function setFieldValue(field: CategoriaStateFields, value: string) {
    dispatch({ type: 'SET_FIELD', field, value });
  }

  function setCategory(category: CriarCategoriaReducerState) {
    dispatch({ type: 'SET_CATEGORY', category });
  }

  return (
    <CriarCategoriaContext.Provider
      value={{
        ...state,
        setFieldValue,
        setCategory,
      }}
    >
      {children}
    </CriarCategoriaContext.Provider>
  );
};

export const useCriarCategorias = () => {
  const contextData = useContext(CriarCategoriaContext);
  if (!contextData) {
    throw new Error(
      'O hook "useCriarCategorias" só pode ser usado dentro de seu contexto.',
    );
  }

  return contextData;
};
