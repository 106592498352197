import instance from './instance';

interface OutputAdicionarRespondenteQuarentena {
  success: boolean;
}

export const adicionaRespondenteQuarentena = async ({
  pesquisa_id,
  identificador,
}: {
  pesquisa_id: number;
  identificador: string;
}): Promise<OutputAdicionarRespondenteQuarentena> => {
  const response = await instance.post('/quarentena/respondente/inserir', {
    pesquisa_id,
    identificador,
  });

  return response.data;
};
