import instance from './instance';

interface ProcessarListaData {
  success: boolean;
}

interface ProcessarListaPayload {
  data: ProcessarListaData;
}

const processarLista = (body: {
  lista_id: number;
  status_id: number;
}): Promise<ProcessarListaPayload> => instance.put('/lista/processar', body);

export default processarLista;
