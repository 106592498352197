/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from './instance';

const enviaEmailTeste = (body: {
  emails: string[] | string;
  emailConfig?: any;
  envioId: number;
  idiomaId: number;
}) => instance.post('/email-teste', body);

export default enviaEmailTeste;
