import Loader from 'components/Loader';
import React from 'react';

import { permissaoSomenteAdminSistema } from '../config/Roles';
import { PrivateRoute } from './PrivateRoute';

const CriarOrganizacao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Organizacoes/CriarOrganizacao" */ 'pages/Organizacoes/CriarOrganizacao'
    ),
);
const Organizacoes = React.lazy(
  () =>
    import(/* webpackChunkName: "Organizacoes" */ 'pages/Organizacoes/index'),
);
const Administracao = React.lazy(
  () => import(/* webpackChunkName: "Administracao" */ 'pages/Administracao'),
);
const Metricas = React.lazy(
  () => import(/* webpackChunkName: "Metricas" */ 'pages/Metricas'),
);

export const rotasAdminSistema = (HomeLayout: React.FC) => {
  return {
    path: '/',
    element: (
      <PrivateRoute permission={permissaoSomenteAdminSistema}>
        <React.Suspense fallback={<Loader />}>
          <HomeLayout />
        </React.Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        path: 'admin/',
        element: <Administracao />,
      },
      {
        path: 'administracao/metricas',
        element: <Metricas />,
      },
      {
        path: 'organizacoes/listagem',
        element: <Organizacoes />,
      },
      {
        path: 'organizacoes/CriaOrganizacao',
        element: <CriarOrganizacao />,
      },
    ],
  };
};
