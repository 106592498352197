import instance from './instance';
import { Estatistica_link } from '../../entities/pesquisa/estatistica_link';

const getEstatisticaLinkByPesquisaId = async (
  pesquisa_id: number,
): Promise<Estatistica_link> => {
  const { data: response } = await instance.get(
    `/link/estatistica/${pesquisa_id}/`,
  );
  return response.data;
};

export default getEstatisticaLinkByPesquisaId;
