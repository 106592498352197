import instance from './instance';

interface InserirListaData {
  success: boolean;
}

interface InserirListaPayload {
  data: InserirListaData;
}

interface InserirContatoListaProps {
  contatoInfo: Record<string, string>;
  listaId: number;
}

const inserirContatoLista = (
  body: InserirContatoListaProps,
): Promise<InserirListaPayload> =>
  instance.post('/lista/contato/inserir', body);

export default inserirContatoLista;
