import instance from './instance';
import { Envio } from './types';

interface ExcluirEnvioData {
  success: boolean;
  result?: Envio[];
}

interface ExcluirEnvioPayload {
  data: ExcluirEnvioData;
}

const excluirEnvio = async (body: {
  id: number;
}): Promise<ExcluirEnvioPayload> => instance.put('/envio/excluir', body);

export default excluirEnvio;
