import React from 'react';
import { Container } from 'reactstrap';
import styles from './styles.module.scss';

interface CategorizacaoScreenContainerProps {
  titulo: string;
  descricao: string;
  children: React.ReactNode;
}

function CategorizacaoScreenContainer({
  titulo,
  children,
  descricao,
}: CategorizacaoScreenContainerProps) {
  return (
    <Container>
      <h5 className="text-primary titulo-negrito">{titulo}</h5>
      <p className={styles.description}>{descricao}</p>
      <Container className={styles.container}>{children}</Container>
    </Container>
  );
}

export default CategorizacaoScreenContainer;
