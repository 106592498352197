import LocalStorageEnum from 'enums/LocalStorageEnum';
import TipoRolesUsuariosSistemaEnum from 'enums/TipoRolesUsuariosSistemaEnum';
import RolesUsuariosSistemaEnum from 'enums/RolesUsuariosSistemaEnum';

type RolesType = {
  [key: number]: TipoRolesUsuariosSistemaEnum;
};

const roles: RolesType = {
  [RolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO]:
    TipoRolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO,
  [RolesUsuariosSistemaEnum.ADMIN_SISTEMA]:
    TipoRolesUsuariosSistemaEnum.ADMIN_SISTEMA,
  [RolesUsuariosSistemaEnum.COLABORADOR]:
    TipoRolesUsuariosSistemaEnum.COLABORADOR,
  [RolesUsuariosSistemaEnum.VISUALIZADOR]:
    TipoRolesUsuariosSistemaEnum.VISUALIZADOR,
};

export const getRoleUsuarioAutenticado = ():
  | TipoRolesUsuariosSistemaEnum
  | undefined => {
  const localStorageData = JSON.parse(
    localStorage.getItem(LocalStorageEnum.PAYLOAD) || '{}',
  );

  if (!localStorageData?.role_id) {
    return undefined;
  }

  return roles[localStorageData.role_id] || undefined;
};
