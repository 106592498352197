import instance from './instance';

interface RemoverListaPayload {
  success: boolean;
}

const removerListaFiltro = async (
  lista_id: number,
): Promise<RemoverListaPayload> => {
  const { data } = await instance.delete('/lista/filtros/remover/', {
    data: { lista_id },
  });

  return data;
};

export default removerListaFiltro;
